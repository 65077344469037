import { IAuth } from './auth/authServiceFactory';
import { CartService } from './cart/cart';
import { CurrencyService } from './currency/currency';
import { getPublicEnv } from './env/env';
import { FetchService } from './fetch/fetch';
import { fetchServiceFactory } from './fetch/fetchFabric';
import { InstallationPartners } from './installationPartners/InstallationPartners';
import { FirebasePerformanceService } from './performance/FirebasePerformance';
import { performanceServiceFactory } from './performance/performanceServiceFactory';
import { ProjectService } from './project/project';
import { PriceService } from './price/price';
import { TranslationService } from './translation/translation';
import { UserService } from './user/user';
import { ChannelService } from './channel/channel';

let servicesCache: RootServices | null = null;

export const getRootServices = (auth: IAuth): RootServices => {
    if (servicesCache) {
        return servicesCache;
    }
    const fetch = fetchServiceFactory(auth);
    const currency = new CurrencyService(fetch);
    const channel = new ChannelService();
    const cart = new CartService(fetch, currency.getCurrentCurrency());
    const translation = new TranslationService(fetch);
    const project = new ProjectService(fetch);
    const price = new PriceService(fetch);
    const installationPartners = new InstallationPartners(fetch);
    const user = new UserService(fetch);
    const performance = typeof window === 'object' ? performanceServiceFactory() : null;

    const allServices = {
        auth,
        fetch,
        cart,
        project,
        price,
        installationPartners,
        user,
        performance,
        translation,
        currency,
        channel,
    };

    if (typeof document !== 'undefined') {
        servicesCache = allServices;

        getPublicEnv('NEXT_PUBLIC_ENVIRONMENT_NAME')
            .then((env) => {
                if (env === 'local' || env === 'DEV') {
                    // eslint-disable-next-line no-console
                    console.log('[SERVICES] You can use window.services to call some services methods.');

                    // @ts-expect-error
                    window.services = servicesCache;
                }
            })
            .catch(() => {});
    }

    return allServices;
};

export interface RootServices {
    auth: IAuth;
    fetch: FetchService;
    cart: CartService;
    project: ProjectService;
    price: PriceService;
    installationPartners: InstallationPartners;
    user: UserService;
    performance: FirebasePerformanceService | null;
    translation: TranslationService;
    currency: CurrencyService;
    channel: ChannelService;
}
