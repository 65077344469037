const availableOptionNames = [
    'double_width',
    'single_width',
    'wardrobe_width',
    'height',
    'depth',
    'color',
    'material',
    'column_width',
    'drawer_height',
    'hanger_material',
    'unknown',
    'door_side',
    'door_width',
    'double_drawer_height',
    'quad_drawer_height',
    'shelve_material',
    'shelve_height',
    'front_hanger_width',
    'hanger_material',
    'hinges_number',
    'legs_number',
    'hanger_box_height',
    'shelve_box_height',
    'drawer_box_height',
    'spacing_box_height',
    'kitchen_room_width',
    'kitchen_room_depth',
    'kitchen_room_height',
    'kitchen_cabinet_width',
    'kitchen_cabinet_height',
    'countertop_material',
    'kitchen_layout_id',
    'drawer_quad_box_height',
    'kitchen_countertop_item_position',
    'kitchen_fridge_height',
    'kitchen_fridge_width',
    'kitchen_door_height',
    'kitchen_door_width',
    'fridge_align',
    'kitchen_countertop_height',
    'kitchen_cabinet_depth',
    'room_align',
    'kitchen_window_x',
    'kitchen_window_y',
    'kitchen_window_width',
    'kitchen_window_height',
    'kitchen_cover_bar_width',
    'kitchen_handle',
    'room_floor',
    'room_wall',
    'room_wall_a',
    'room_wall_b',
    'room_wall_c',
    'room_wall_d',
] as const;

export type OptionNames = typeof availableOptionNames[number];

export function isOptionNames(value: unknown): value is OptionNames {
    return typeof value === 'string' && availableOptionNames.includes(value as OptionNames);
}
