import '../styles/globals.css';
import '../styles/carousel.css';
import { ThemeProvider } from 'styled-components';
import { getStore } from '../store';
import { Provider } from 'react-redux';
import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { AppProps } from '../types/common';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-day-picker/dist/style.css';
import './style.css';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import { ToastrContainer } from '../components/ToastrContainer/ToastrContainer';
import { CookiesConsent } from '../components/CookiesConsent/CookiesConsent';
import { authServiceFactory } from '../services/auth/authServiceFactory';
import { getRootServices } from '../services';
import { setServices } from '../store/dependency/actions';
import AnimationProvider from '../components/AnimationProvider/AnimationProvider';
import { useRouter } from 'next/router';
import { pushEvent, setPage } from '../services/analytics/analytics';
import { RouteName } from '../constants/router';
import { CartSidePanelProvider } from '../components/CartSidePanel/CartSidePanelProvider';
import Head from 'next/head';
import { LanguageProvider } from '../components/Translations/LanguageProvider';
import { CurrencyProvider } from '../components/Currency/CurrencyProvider';
import { ChannelProvider } from '../components/Channel/ChannelProvider';
import { OgMetaTags } from '../components/OgMetaTags/OgMetaTags';
import { HeaderDependenciesManager } from '../components/HeaderDependenciesManager/HeaderDependenciesManager';

// import dynamic from 'next/dynamic';
// const AnimationProvider = dynamic(() => import('../components/AnimationProvider/AnimationProvider'));

const theme = {};

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();
    const store = useMemo(() => getStore(pageProps.initialReduxActions || []), [pageProps.initialReduxActions]);

    useEffect(() => {
        let eventStartSent = false;

        const handleRouteChange = () => {
            setPage(document.title, location.href);

            if (location.pathname.indexOf(RouteName.Configurator) !== -1) {
                if (eventStartSent === false) {
                    eventStartSent = true;
                    pushEvent('start_configurator', {});
                }
            } else {
                eventStartSent = false;
            }
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    useLayoutEffect(() => {
        if (typeof document !== 'undefined') {
            const auth = authServiceFactory(undefined, pageProps.token || undefined);
            const services = getRootServices(auth);

            store.dispatch(setServices(services));
        }
    }, [pageProps.token, store]);

    return (
        <ThemeProvider theme={theme}>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            </Head>
            <ChannelProvider>
                <AnimationProvider animation={pageProps.animation}>
                    <Provider store={store}>
                        <LanguageProvider>
                            <OgMetaTags />
                            <HeaderDependenciesManager />
                            <CurrencyProvider>
                                <CartSidePanelProvider>
                                    <ToastrContainer />
                                    {/* @ts-ignore */}
                                    <Component {...pageProps} />
                                    <CookiesConsent />
                                </CartSidePanelProvider>
                            </CurrencyProvider>
                        </LanguageProvider>
                    </Provider>
                </AnimationProvider>
            </ChannelProvider>
        </ThemeProvider>
    );
}

export default MyApp;
