import React, { FC } from 'react';
import { MessageStyled } from './Message.styled';

export interface MessageProps {
    error?: boolean;
    success?: boolean;
    message?: string;
    dataTestId?: string;
}

export const Message: FC<MessageProps> = ({ message, success, error, dataTestId }) => {
    if (!message) {
        return null;
    }

    return (
        <MessageStyled
            success={success}
            error={error}
            data-test={`message-${success ? 'success' : error ? 'error' : 'info'}`}
            data-test-id={dataTestId}
        >
            {message}
        </MessageStyled>
    );
};
