import { WardrobeTreeVisitor } from '../visitor/domain';
import { HangerFrontNode } from './HangerFrontNode';
import { Node } from '../../common/nodes/Node';
import { ShelveNode } from './ShelveNode';

export class HangerFrontWithShelveNode extends Node<{}> {
    hanger: HangerFrontNode;
    shelve: ShelveNode;

    constructor(hanger: HangerFrontNode, shelve: ShelveNode) {
        super({});
        this.hanger = hanger;
        this.shelve = shelve;
        this.addChild(hanger);
        this.addChild(shelve);
    }

    visit<T extends WardrobeTreeVisitor<any>>(visitor: T): ReturnType<T['visitHangerFrontWithShelve']> {
        return visitor.visitHangerFrontWithShelve(this);
    }
}
