import { OptionName } from '../components/SidePanel/constants';

export enum RouteName {
    Home = '/',
    Configurator = '/configurator',
    KitchenConfigurator = '/kitchen-configurator',
    ThankYou = '/thank-you',
    ThankYouQuote = '/thank-you-quote',
    Checkout = '/checkout',
    Static = '/static',
    ProjectSaved = '/project-saved',
}

export const termsAndConditionsRoute = `${RouteName.Static}?lang=en_US&slug=t-and-c`;
export const privacyPolicyRoute = `${RouteName.Static}?lang=en_US&slug=privacy-policy`;

export type ConfiguratorQueryParams = {
    option: OptionName | 'menu'; // still using in mobile and summary
    projectId: string;
    godMod: string;
    orderItemId?: string;
    token?: string;
};

export type CommonConfiguratorQueryParams = {
    projectId: string;
    orderItemId?: string;
    token?: string;
};
