import { Column } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';

export const INITIAL_COLUMN_VALUES: Column & { thickness: number } = {
    depth: 58,
    height: 210,
    width: 100,
    thickness: 1.8,
    elements: [],
    doors: [],
    legs: 4,
    wall: 'A',
};

export const WARDROBE_PARTS = {
    exterior: 'FULL WALL',
    interior: 'SINGLE ELEMENT',
} as const;
