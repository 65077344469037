import { ProjectImageType } from '../../types/Cart';
import { getPublicEnv } from '../env/env';
import { IRI } from '../fetch/domain';
import { fetchUnAuthorizationApi } from '../fetch/fetch';
import { getNumber, isObjectWithKey } from '../validateData';

export const saveImage = async (blob: Blob, type: ProjectImageType = 'common'): Promise<IRI> => {
    const body = new FormData();
    const apiUrl = await getPublicEnv('NEXT_PUBLIC_API_URL');

    body.append('file', blob);
    body.append('type', type);

    const responseData = await fetchUnAuthorizationApi(`${apiUrl}/shop/images`, {
        method: 'POST',
        headers: {
            accept: 'application/json',
        },
        body,
    });

    if (!isObjectWithKey(responseData)) {
        throw new Error('Invalid response');
    }

    return `/api/v2/shop/images/${getNumber(responseData.id)}`;
};
