import { useEffect, useState } from 'react';

export const useWindowWidth = (): number => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleWindowSizeChange = () => {
                setWidth(window.innerWidth);
            };

            setWidth(window.innerWidth);
            window.addEventListener('resize', handleWindowSizeChange);

            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            };
        }
    }, [setWidth]);

    return width;
};
