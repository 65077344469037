import { Selector } from 'react-redux';
import type { RootState } from '../index';
import { AllColumnConfigurations } from './reducer';
import { ColumnConfiguration } from '../../types/Configuration';
import { getWardrobeDimensions } from '../products/selectors';

export const getConfigurations: Selector<RootState, AllColumnConfigurations> = (state) =>
    state.configurations.allConfigurations;

export const getConfiguration =
    (id: string): Selector<RootState, ColumnConfiguration | null> =>
    (state) =>
        state.configurations.allConfigurations[id] || null;

export const getConfigurationPrice =
    (id: string): Selector<RootState, number | null> =>
    (state) =>
        state.configurations.allConfigurationsPrices[id] ?? null;

export const getIsFetchingConfigurationPrice =
    (id: string): Selector<RootState, boolean> =>
    (state) =>
        state.configurations.allFetchingConfigurationPriceIds.includes(id);

export const getColumnProductCodeByWidth =
    (columnWidth: number) =>
    (state: RootState): 'double_closet' | 'single_closet' => {
        const { singleClosetWidth } = getWardrobeDimensions(state);
        const singleClosetMaxWidth = singleClosetWidth?.max || 0;

        return columnWidth > singleClosetMaxWidth ? 'double_closet' : 'single_closet';
    };
