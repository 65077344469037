import { Selector } from 'react-redux';
import type { RootState } from '../index';
import { Product } from '../../types/Product';
import { getFormattedProductOptions, WardrobeDimensions } from './utils';
import {
    ProductCode,
    isShelveCode,
    isDoorHandleCode,
    isWardrobeCode,
} from '../../services/products/domain/ProductCodes';
import { getVisibleColumn } from '../wardrobe/selectors';
import { DOORS_SELECTION } from '../../constants/products/DoorSelection.enum';
import {
    DoorHandlePositionCode,
    isCustomOptionSelect,
    OptionSelect,
} from '../../services/products/domain/ThreeDParameter';

export const getProductByType =
    (type: ProductCode): Selector<RootState, Product | undefined> =>
    (state) =>
        state.products.allProducts.find((product) => product.code === type);

export const getWardrobeProducts: Selector<RootState, Product[]> = (state) =>
    state.products.allProducts.reduce((wardrobeProducts: Product[], product) => {
        if (isWardrobeCode(product.code)) {
            wardrobeProducts.push(product);
        }

        return wardrobeProducts;
    }, []);

export const getShelveProducts: Selector<RootState, Product[]> = (state) =>
    state.products.allProducts.reduce((shelveProducts: Product[], product) => {
        if (isShelveCode(product.code)) {
            shelveProducts.push(product);
        }

        return shelveProducts;
    }, []);

const getDoorHandleProducts: Selector<RootState, Product[]> = (state) =>
    state.products.allProducts.reduce((doorHandleProducts: Product[], product) => {
        if (isDoorHandleCode(product.code)) {
            doorHandleProducts.push(product);
        }

        return doorHandleProducts;
    }, []);

export const getDoorHandleProductsBaseOnDoor = (doors: Product[]) => (state: RootState) => {
    if (doors.length === 0) {
        return [];
    }

    return getDoorHandleProducts(state).filter((handler) => {
        const type = handler.productTaxons.map(({ taxon }) => taxon.code);

        return doors.every((door) =>
            type.includes(door?.code === 'door_glass' ? 'GLASS_DOOR_HANDLES' : 'WOODEN_DOOR_HANDLES'),
        );
    });
};

export const getAvailableMaterialCodeFromWardrobe: Selector<RootState, OptionSelect[] | null> = (state) => {
    const wardrobe = getProductByType('wardrobe')(state);

    if (wardrobe) {
        const materialOptions = wardrobe.threeDParameters.find((option) => option.genericCode === 'MATERIAL');

        if (isCustomOptionSelect(materialOptions)) {
            return materialOptions.optionSelects;
        }
    }

    return null;
};

export const getDoorHandlePositions: Selector<RootState, OptionSelect[] | null> = (state) => {
    const door = getProductByType('door')(state);

    if (door) {
        const doorSide = door.threeDParameters.find((option) => option.name === 'door_side');

        if (isCustomOptionSelect(doorSide)) {
            return doorSide.optionSelects;
        }
    }

    return null;
};

export const getDoorHandlePositionByCode =
    (code: DoorHandlePositionCode): Selector<RootState, OptionSelect | undefined> =>
    (state) => {
        const doorHandlePositions = getDoorHandlePositions(state);

        return doorHandlePositions?.find(({ value }) => value === code);
    };

const countWardrobeDimensions = (dimensions: WardrobeDimensions, product: Product) => {
    const { width, height, depth } = getFormattedProductOptions(product.threeDParameters);

    if (product.code === 'wardrobe') {
        dimensions.wardrobeWidth = width;
        dimensions.wardrobeHeight = height;
        dimensions.wardrobeDepth = depth;
    }
    if (product.code === 'single_closet') {
        dimensions.singleClosetWidth = width;
    }
    if (product.code === 'double_closet') {
        dimensions.doubleClosetWidth = width;
    }

    return dimensions;
};

export const getWardrobeDimensions: Selector<RootState, WardrobeDimensions> = (state) =>
    state.products.allProducts.reduce<WardrobeDimensions>(countWardrobeDimensions, {
        singleClosetWidth: null,
        doubleClosetWidth: null,
        wardrobeWidth: null,
        wardrobeHeight: null,
        wardrobeDepth: null,
    });

export const getDoorProductForWardrobe = (state: RootState): Product[] => {
    const columns = getVisibleColumn(state);

    return columns.reduce<Product[]>((acc, column) => {
        if (column.door && !acc.includes(column.door)) {
            acc.push(column.door);
        }

        return acc;
    }, []);
};

export const getSelectedDoorProductForWardrobe = (state: RootState): Product | null => {
    const columnIndex = state.wardrobe.selectedColumnIndex;

    return getSelectedDoorProductForColumn(state, columnIndex);
};

export const getSelectedDoorProductForColumn = (state: RootState, columnIndex: number | null): Product | null => {
    const selectedDoorProduct = state.wardrobe.doorSelection;

    if (columnIndex !== null) {
        const selectedRawColumn = state.wardrobe.columns[columnIndex];

        if (selectedRawColumn === undefined) {
            return null;
        }
        if (
            !(
                selectedRawColumn.doorSelection === DOORS_SELECTION.WITHOUT_DOORS ||
                (selectedDoorProduct === DOORS_SELECTION.WITHOUT_DOORS &&
                    selectedRawColumn.doorSelection === DOORS_SELECTION.NOT_SELECTED)
            )
        ) {
            return selectedRawColumn.door != null ? selectedRawColumn.door : state.wardrobe.doors;
        }
    } else {
        if (selectedDoorProduct === DOORS_SELECTION.WITH_DOORS) {
            return state.wardrobe.doors;
        }
    }

    return null;
};
