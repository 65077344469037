import { Product } from '../../../../types/Product';
import { ProductWoodenMaterialOption } from '../../../products/domain/ThreeDParameter';
import { WardrobeTreeVisitor } from '../visitor/domain';
import { ColumnNode } from './ColumnNode';
import { NodeWithProduct } from './NodeWithProduct';

interface ShelveNodeParams {
    product: Product;
    box_height: number;
    material: ProductWoodenMaterialOption;
    width?: number;
    depth?: number;
}

export class ShelveNode extends NodeWithProduct<ShelveNodeParams> {
    getParams(): ShelveNodeParams {
        const columNode = this.findParentByInstance(ColumnNode);

        if (columNode === null) {
            return super.getParams();
        }

        const { width, depth } = columNode.getParams();

        return {
            ...super.getParams(),
            width,
            depth,
        };
    }

    visit<T extends WardrobeTreeVisitor<any>>(visitor: T): ReturnType<T['visitShelveNode']> {
        return visitor.visitShelveNode(this);
    }
}
