export const PUBLIC_ENV_CODES = [
    'NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY',
    'NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN',
    'FIREBASE_PROJECT_ID',
    'NEXT_PUBLIC_FIREBASE_APP_ID',
    'NEXT_PUBLIC_API_URL',
    'NEXT_PUBLIC_ENVIRONMENT_NAME',
    'MEDIA_DOMAIN_URL',
    'NEXT_PUBLIC_API_DOMAIN',
    'GOOGLE_RECAPTCHA_SITE_KEY',
    'CRISP_WEBSITE_ID',
    'FEAT_FLAG_REDIRECT',
    'FEAT_FLAG_ACCOUNT',
    'FEAT_FLAG_DRAG',
    'DEFAULT_LOGIN_REDIRECT',
    'GTM_TRACKING_ID',
    'HOT_JAR_TRACKING_ID',
    'COOKIE_POLICY_URL',
    'FEAT_FLAG_MENU',
] as const;

export type PublicEnv = {
    [key in (typeof PUBLIC_ENV_CODES)[number]]: string;
};
