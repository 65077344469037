import { createReducer } from '@reduxjs/toolkit';
import { Column, ColumnElement, WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { DOORS_SELECTION } from '../../constants/products/DoorSelection.enum';
import { INITIAL_COLUMN_VALUES } from '../../constants/products/Wardrobe';
import { DoorHandlePositionCode, ProductWoodenMaterialOption } from '../../services/products/domain/ThreeDParameter';
import { DoorHandleCode, ShelveCode, WardrobeCode } from '../../services/products/domain/ProductCodes';
import { Product } from '../../types/Product';
import { fetchWardrobePrice, setAllConfigurations } from '../configurations/actions';
import { AllColumnConfigurations } from '../configurations/reducer';
import { convertValueFromCmToMm, convertValueFromMmToCm } from '../products/utils';
import {
    assignConfigurationToColumn,
    assignDoor,
    assignDoorHandlePosition,
    assignDoorHandleType,
    assignShelves,
    setColumns,
    setColumnsWidth,
    setElementToSelectedColumn,
    setLazyWidth,
    setLed,
    setSelectedColumnDimensions,
    setSelectedColumnIndex,
    setVisibility,
    setWallDimensions,
    setWardrobeDimensions,
    setWardrobeType,
    setWoodColor,
} from './actions';
import { sortColumnByWallName } from './helpers';
import { DEFAULT_THICKNESS } from '@formify/frontend-wardrobe-renderer/dist/modules/constants';

export interface WardrobeColumn {
    data: Pick<Column, 'depth' | 'width' | 'height' | 'thickness'>;
    door: Product | null;
    shelveType?: ShelveCode;
    doorHandleType?: DoorHandleCode | null;
    doorHandlePosition: DoorHandlePositionCode;
    hidden: boolean;
    configurationId: string | null;
    elements: ColumnElement[];
    doorSelection: DOORS_SELECTION;
    color: ProductWoodenMaterialOption;
    wall: WardrobeWall;
}

interface WardrobeState {
    allConfigurations: AllColumnConfigurations;
    selectedColumnIndex: null | number;
    wardrobeWidth: null | number;
    previousSelectedColumnIndex: null | number;
    totalPrice: null | number;
    totalPricePending: boolean;
    columns: WardrobeColumn[];
    led: boolean;
    wardrobeType: WardrobeCode;
    doors: null | Product;
    shelves: null | ShelveCode;
    doorSelection: DOORS_SELECTION;
    lazyWardrobeWidth: null | number;
}

const initialState: WardrobeState = {
    allConfigurations: {},
    selectedColumnIndex: null,
    wardrobeWidth: null,
    previousSelectedColumnIndex: null,
    totalPrice: null,
    totalPricePending: false,
    wardrobeType: 'wardrobe',
    columns: [
        {
            data: {
                depth: convertValueFromCmToMm(INITIAL_COLUMN_VALUES.depth),
                height: convertValueFromCmToMm(INITIAL_COLUMN_VALUES.height),
                width: convertValueFromCmToMm(INITIAL_COLUMN_VALUES.width),
            },
            door: null,
            hidden: false,
            configurationId: null,
            elements: [],
            color: 'NATURAL_WOOD',
            shelveType: undefined,
            doorSelection: DOORS_SELECTION.NOT_SELECTED,
            doorHandlePosition: 'RIGHT',
            wall: 'A',
        },
    ],
    led: false,
    doors: null,
    doorSelection: DOORS_SELECTION.WITHOUT_DOORS,
    shelves: null,
    lazyWardrobeWidth: null,
};

const getVisibleColumnWall =
    (wall: WardrobeWall) =>
    (state: WardrobeState): WardrobeColumn[] =>
        state.columns.filter((column) => !column.hidden && column.wall === wall);

function getWardrobeShape(state: WardrobeState): WardrobeCode {
    const columnsA = getVisibleColumnWall('A')(state);
    const columnsB = getVisibleColumnWall('B')(state);
    const columnsC = getVisibleColumnWall('C')(state);

    if (columnsA.length > 0 && columnsB.length > 0 && columnsC.length > 0) {
        return 'u_shaped_wardrobe';
    }

    if (columnsA.length === 0 && columnsB.length > 0 && columnsC.length > 0) {
        return 'l_shaped_wardrobe_right';
    }
    if (columnsA.length > 0 && columnsB.length > 0 && columnsC.length === 0) {
        return 'l_shaped_wardrobe_left';
    }

    return state.wardrobeType === 'wardrobe' ? 'wardrobe' : 'wardrobe_free_standing';
}

function fixElementsOutOfHeightRange(columns: WardrobeColumn[]) {
    return columns.map((column, i) => {
        if (column.configurationId !== null || column.hidden) {
            return column;
        }
        const elements = [...column.elements];

        const heightInside =
            convertValueFromMmToCm(column.data.height) - (column.data.thickness || DEFAULT_THICKNESS) * 2;

        while (heightInside < elements.reduce((sum, element) => sum + element.heightBox, 0)) {
            elements.shift();
        }

        return {
            ...column,
            elements,
        };
    });
}

export function fixCornerConfiguration(columns: WardrobeColumn[], state: WardrobeState) {
    const [firstVisibleBWallColumn, ...restVisibleBWallColumns] = columns.filter(
        (column) => column.wall === 'B' && column.hidden === false,
    );

    const wallAIsExist = columns.some((column) => column.wall === 'A' && column.hidden === false);
    const wallCIsExist = columns.some((column) => column.wall === 'C' && column.hidden === false);

    if (wallAIsExist && firstVisibleBWallColumn) {
        fixCornerColumnConfiguration(columns, state, firstVisibleBWallColumn);
    }
    if (wallCIsExist) {
        const columnToFix = restVisibleBWallColumns[restVisibleBWallColumns.length - 1];

        if (columnToFix) {
            fixCornerColumnConfiguration(columns, state, columnToFix);
        }
    }
}

function fixCornerColumnConfiguration(columns: WardrobeColumn[], state: WardrobeState, columnToFix: WardrobeColumn) {
    const configId = columnToFix.configurationId;

    if (configId === null) {
        const indexToChange = columns.findIndex((column) => column === columnToFix);
        const column = columns[indexToChange];

        if (column) {
            column.elements = column.elements.filter(
                (element) => element.type !== 'drawer_box_double' && element.type !== 'drawer_box_quadruple',
            );
        }
    } else if (state.allConfigurations[configId]?.isCorrectForCorner === false) {
        const safeConfigurations = Object.entries(state.allConfigurations).filter(
            ([, value]) => value.isCorrectForCorner && value.isEnabled,
        );

        // const randomCorrect = safeConfigurations[Math.floor(Math.random() * safeConfigurations.length)];
        const firstCorrect = safeConfigurations[0];

        if (firstCorrect) {
            const [firstCorrectId] = firstCorrect;
            const indexToChange = columns.findIndex((column) => column === columnToFix);
            const column = columns[indexToChange];

            if (column) {
                column.configurationId = firstCorrectId;
            }
        }
    }
}

export default createReducer(initialState, (builder) => {
    builder.addCase(setAllConfigurations, (state, action) => {
        state.allConfigurations = action.payload;
    });

    builder.addCase(setLed, (state, { payload }) => {
        state.led = payload;
    });

    builder.addCase(setLazyWidth, (state, { payload }) => {
        state.lazyWardrobeWidth = payload;
    });

    builder.addCase(setElementToSelectedColumn, (state, { payload }) => {
        if (state.selectedColumnIndex !== null) {
            const column = state.columns[state.selectedColumnIndex];

            if (column) {
                column.elements = payload;
                column.configurationId = null;
            }
        }
    });

    builder.addCase(setWardrobeType, (state, { payload }) => {
        state.wardrobeType = getWardrobeShape(state);

        if (state.wardrobeType === 'wardrobe' || state.wardrobeType === 'wardrobe_free_standing') {
            state.wardrobeType = payload;
        }
    });

    builder.addCase(setVisibility, (state, { payload }) => {
        state.columns = state.columns.map((column: WardrobeColumn, index: number) => ({
            ...column,
            hidden: payload <= index,
        }));

        fixCornerConfiguration(state.columns, state);

        state.wardrobeType = getWardrobeShape(state);
    });

    builder.addCase(assignDoor, (state, { payload }) => {
        const columnIndex = state.selectedColumnIndex;

        if (columnIndex === null) {
            state.doors = payload.door;
            state.doorSelection = payload.door ? DOORS_SELECTION.WITH_DOORS : DOORS_SELECTION.WITHOUT_DOORS;
        }

        const doNotReplaceDoorHandle = state.columns.every((column: WardrobeColumn) => {
            const { doorHandleType } = column;

            return (doorHandleType && payload.availableHandles.includes(doorHandleType)) || doorHandleType === null;
        });

        state.columns = state.columns.map((column: WardrobeColumn, index: number) => {
            const { doorHandleType } = column;

            if (columnIndex === null || index === columnIndex) {
                return {
                    ...column,
                    door: payload.door,
                    doorSelection: payload.door ? DOORS_SELECTION.WITH_DOORS : DOORS_SELECTION.WITHOUT_DOORS,
                    doorHandleType: doNotReplaceDoorHandle
                        ? doorHandleType
                        : payload.availableHandles[0] || doorHandleType,
                };
            } else {
                return {
                    ...column,
                    doorHandleType: doNotReplaceDoorHandle
                        ? doorHandleType
                        : payload.availableHandles[0] || doorHandleType,
                };
            }
        });
    });

    builder.addCase(assignShelves, (state, { payload }) => {
        const columnIndex = state.selectedColumnIndex;

        if (columnIndex === null) {
            state.shelves = payload.shelveType;
        }
        state.columns = (state.columns as WardrobeColumn[]).map((column: WardrobeColumn, index: number) => {
            if (columnIndex === null || index === columnIndex) {
                return {
                    ...column,
                    shelveType: payload.shelveType,
                };
            } else {
                return column;
            }
        });
    });

    builder.addCase(assignDoorHandleType, (state, { payload }) => {
        state.columns = state.columns.map(
            (column): WardrobeColumn => ({
                ...column,
                doorHandleType: payload,
            }),
        );
    });

    builder.addCase(assignDoorHandlePosition, (state, { payload }) => {
        const selectedIndex = state.selectedColumnIndex;

        state.columns = state.columns.map((column, index): WardrobeColumn => {
            if (index === selectedIndex) {
                return {
                    ...column,
                    doorHandlePosition: payload,
                };
            }

            return column;
        });
    });

    builder.addCase(assignConfigurationToColumn, (state, action) => {
        const currentData = state.columns[action.payload.columnIndex];
        const firstColumn = state.columns[0];

        if (currentData) {
            state.columns[action.payload.columnIndex] = {
                ...currentData,
                configurationId: action.payload.configurationId,
            };
        } else {
            state.columns[action.payload.columnIndex] = {
                shelveType: undefined,
                data: {
                    depth: convertValueFromCmToMm(INITIAL_COLUMN_VALUES.depth),
                    height: convertValueFromCmToMm(INITIAL_COLUMN_VALUES.height),
                    width: convertValueFromCmToMm(INITIAL_COLUMN_VALUES.width),
                },
                door: null,
                hidden: true,
                doorHandlePosition: 'RIGHT',
                configurationId: action.payload.configurationId,
                doorSelection: DOORS_SELECTION.NOT_SELECTED,
                color: firstColumn?.color || 'NATURAL_WOOD',
                wall: 'A',
                elements: [],
            };
        }
        fixCornerConfiguration(state.columns, state);
    });

    builder.addCase(setWallDimensions, (state, action) => {
        const { type, value, wall } = action.payload;

        state.columns = fixElementsOutOfHeightRange(
            state.columns.map(
                (column): WardrobeColumn => ({
                    ...column,
                    data: {
                        ...column.data,
                        [type]: column.wall === wall ? value : column.data[type],
                    },
                }),
            ),
        );
    });

    builder.addCase(setWardrobeDimensions, (state, action) => {
        const { type, value } = action.payload;

        state.columns = fixElementsOutOfHeightRange(
            state.columns.map(
                (column): WardrobeColumn => ({
                    ...column,
                    data: {
                        ...column.data,
                        [type]: value,
                    },
                }),
            ),
        );
    });

    builder.addCase(setColumnsWidth, (state, action) => {
        const { columnsWidth, wall } = action.payload;

        const getWallWidths = (wallSign: WardrobeWall) =>
            wall === wallSign
                ? columnsWidth
                : state.columns
                      .filter((column) => column.wall === wallSign && !column.hidden)
                      .map((column) => column.data.width);

        const widthAWall = getWallWidths('A');
        const widthBWall = getWallWidths('B');
        const widthCWall = getWallWidths('C');
        const columnWidths = [...widthAWall, ...widthBWall, ...widthCWall];

        if (columnWidths.length > state.columns.length) {
            state.columns = [...state.columns, ...state.columns];
        }

        state.columns = state.columns.map((column, index) => {
            const data = {
                ...column.data,
            };

            let currentWall: WardrobeWall = 'A';

            if (index >= widthAWall.length && index < widthAWall.length + widthBWall.length) {
                currentWall = 'B';
            }

            if (
                index >= widthAWall.length + widthBWall.length &&
                index < widthAWall.length + widthBWall.length + widthCWall.length
            ) {
                currentWall = 'C';
            }

            const newDataWidth = columnWidths[index];

            if (newDataWidth) {
                data.width = newDataWidth;
            }

            return {
                ...column,
                data,
                hidden: columnWidths[index] === undefined,
                wall: currentWall,
            };
        });

        fixCornerConfiguration(state.columns, state);
        state.wardrobeType = getWardrobeShape(state);
    });

    builder.addCase(setSelectedColumnDimensions, (state, action) => {
        const { selectedColumnIndex } = state;
        const { type, value } = action.payload;

        if (selectedColumnIndex === null) {
            return;
        }

        const selectedColumn = state.columns[selectedColumnIndex];

        if (selectedColumn) {
            selectedColumn.data[type] = value;
        }

        state.columns = fixElementsOutOfHeightRange(state.columns);
    });

    builder.addCase(setSelectedColumnIndex, (state, action) => {
        state.selectedColumnIndex = action.payload;
        if (action.payload !== null) {
            state.previousSelectedColumnIndex = action.payload;
        }
    });

    builder.addCase(fetchWardrobePrice.pending, (state) => {
        state.totalPricePending = true;
    });

    builder.addCase(fetchWardrobePrice.fulfilled, (state, action) => {
        state.totalPrice = action.payload.unitPriceWithChildItems;
        state.totalPricePending = false;
    });

    builder.addCase(setColumns, (state, { payload }) => {
        state.columns = [
            ...sortColumnByWallName(payload),
            ...state.columns.slice(payload.length).map((column) => ({
                ...column,
                hidden: true,
                wall: 'C' as const,
            })),
        ];

        fixCornerConfiguration(state.columns, state);
        state.columns = fixElementsOutOfHeightRange(state.columns);

        state.wardrobeWidth = payload.reduce((sum, item) => sum + item.data.width, 0);

        if (state.selectedColumnIndex !== null && payload.length <= state.selectedColumnIndex) {
            state.selectedColumnIndex = null;
            state.previousSelectedColumnIndex = null;
        }
    });

    builder.addCase(setWoodColor, (state, { payload }) => {
        state.columns = state.columns.map(
            (column): WardrobeColumn => ({
                ...column,
                color: payload,
            }),
        );
    });
});
