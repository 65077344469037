import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import thunk, { ThunkDispatch } from 'redux-thunk';
import productsReducer from './products/reducer';
import configurationsReducer from './configurations/reducer';
import layoutReducer from './layout/reducer';
import wardrobeReducer from './wardrobe/reducer';
import projectReducer from './project/reducer';
import { toastrReducer } from './toastr/reducer';
import { mediaReducer } from './media/reducer';
import dependencyReducer from './dependency/reducer';
import { configReducer } from './config/reducer';

export type RootState = ReturnType<typeof reducer>;

type AppDispatch = ThunkDispatch<RootState, null, Action>;

export type ThunkApiConfig = {
    dispatch: AppDispatch;
    state: RootState;
};

const reducer = combineReducers({
    products: productsReducer,
    configurations: configurationsReducer,
    layout: layoutReducer,
    wardrobe: wardrobeReducer,
    project: projectReducer,
    media: mediaReducer,
    toastr: toastrReducer,
    dependency: dependencyReducer,
    config: configReducer,
});

const getStore = (actions: Action[]) => {
    const store = configureStore({
        reducer,
        middleware: [thunk],
    });

    actions.forEach((action) => store.dispatch(action));

    return store;
};

export { getStore };
