import { Product } from '../../../../types/Product';
import { WardrobeTreeVisitor } from '../visitor/domain';
import { NodeWithProduct } from './NodeWithProduct';

interface ChromeDoorHandleNodeParams {
    product: Product;
}

export class ChromeDoorHandleNode extends NodeWithProduct<ChromeDoorHandleNodeParams> {
    visit<T extends WardrobeTreeVisitor<any>>(visitor: T): ReturnType<T['visitChromeDoorHandleNode']> {
        return visitor.visitChromeDoorHandleNode(this);
    }
}
