import { Product } from '../../../../types/Product';
import { WardrobeTreeVisitor } from '../visitor/domain';
import { NodeWithProduct } from './NodeWithProduct';

interface GoldDoorHandleNodeParams {
    product: Product;
}

export class GoldDoorHandleNode extends NodeWithProduct<GoldDoorHandleNodeParams> {
    visit<T extends WardrobeTreeVisitor<any>>(visitor: T): ReturnType<T['visitGoldDoorHandleNode']> {
        return visitor.visitGoldDoorHandleNode(this);
    }
}
