import { CustomOptionsValue } from '../../../../types/Project';
import { SomeNodeWithProduct } from './domain';
import { ProjectVisitor } from './ProjectVisitor';

export class PriceVisitor extends ProjectVisitor {
    getCustomOptionsValues<T extends SomeNodeWithProduct>(node: T): CustomOptionsValue[] {
        const allOption = super.getCustomOptionsValues<T>(node);

        return allOption.reduce<CustomOptionsValue[]>((acc, option) => {
            // TODO filter unnecessary option
            acc.push(option);

            return acc;
        }, []);
    }
}
