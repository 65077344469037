import { getChannelCode } from '../env/env';
import { FetchService } from '../fetch/fetch';
import { isObjectWithKey } from '../validateData';

export class TranslationService {
    private fetch: FetchService;
    private responseCache: { [lang: string]: Promise<unknown> | undefined } = {};
    private normalizeLabels: { [lang: string]: { [key: string]: string } } = {};
    private availableLanguages:
        | {
              code: string;
              name: string;
          }[]
        | null = null;

    constructor(fetch: FetchService) {
        this.fetch = fetch;
    }

    async getAvailableLanguages(): Promise<
        {
            code: string;
            name: string;
        }[]
    > {
        if (this.availableLanguages) {
            return this.availableLanguages;
        }
        const response = await this.fetch.get(`/api/v2/shop/channels/${getChannelCode()}`);

        if (isLanguageSettings(response)) {
            const availableLanguages = response.locales;

            this.availableLanguages = availableLanguages;

            return availableLanguages;
        }

        return [];
    }

    async getLabel(language: string, key: string, defaultValue: string): Promise<string> {
        const labels = await this.getNormalizeLabels(language);
        const label = labels[key];

        this.createLabelIfNotExist(key, defaultValue);

        return label ?? defaultValue;
    }

    async getNormalizeLabels(language: string): Promise<{ [key: string]: string }> {
        const cache = this.normalizeLabels[language];

        if (cache) {
            return cache;
        }

        const translations = await this.getLabels(language);
        const normalizeLabels: { [key: string]: string } = {};

        Object.keys(translations).forEach((key) => {
            const value = translations?.[key]?.[language] ?? '';

            normalizeLabels[key] = value;
        });
        this.normalizeLabels[language] = normalizeLabels;

        return normalizeLabels;
    }

    async getLabels(language: string): Promise<TranslationResponseRaw> {
        const request =
            this.responseCache[language] ??
            Promise.race([
                this.fetch.get(`/api/v2/shop/translations?locale=${language}`),
                new Promise((reject) => {
                    setTimeout(
                        () => {
                            reject(new Error('Timeout'));
                        },
                        5000,
                        null,
                    );
                }),
            ]);

        this.responseCache[language] = request;
        try {
            const values = await request;

            if (isTranslationResponseRaw(values)) {
                return values;
            }
        } catch (error) {
            this.responseCache[language] = undefined;

            return {}; // null
        }

        return {};
    }

    async createLabelIfNotExist(key: string, value: string) {
        if (this.normalizeLabels === null) {
            return false;
        }
        const languages = await this.getAvailableLanguages();
        const isExist = languages.some(({ code }) => this.normalizeLabels[code]?.[key] !== undefined);

        if (isExist) {
            return false;
        }

        languages.forEach(({ code }) => {
            const lang = this.normalizeLabels[code] || {};

            this.normalizeLabels[code] = lang;
            lang[key] = value;
        });

        try {
            await this.fetch.post('/api/v2/shop/translations', {
                key,
                translations: languages.map((language) => ({
                    locale: language.code,
                    value,
                })),
            });

            return true;
            // eslint-disable-next-line no-empty
        } catch (error) {}

        return false;
    }

    applyVariables(label: string, variables: { [key: string]: string | number }): string {
        return label.replace(/\${\s*(\w+)\s*}/gi, (match, key) => `${variables[key] || match}`);
    }
}

type TranslationResponseRaw = { [key: string]: { [lang: string]: string } };

function isTranslationResponseRaw(value: any): value is TranslationResponseRaw {
    return isObjectWithKey(value) && Object.values(value).every(isObjectWithKey);
}

interface LanguageSettings {
    locales: {
        code: string;
        name: string;
    }[];
    defaultLocale: {
        code: string;
    };
}

function isLanguageSettings(value: any): value is LanguageSettings {
    return (
        typeof value === 'object' &&
        value !== null &&
        Array.isArray(value.locales) &&
        value.locales.every(isLanguage) &&
        isLanguage(value.defaultLocale)
    );
}

function isLanguage(value: any): value is { code: string } {
    return (
        typeof value === 'object' && value !== null && typeof value.code === 'string' && typeof value.name === 'string'
    );
}
