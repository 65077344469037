import { createReducer } from '@reduxjs/toolkit';
import { fetchColumnConfigurationPrice, setAllConfigurations } from './actions';
import { AllConfigurationsPrices, ColumnConfiguration } from '../../types/Configuration';

export type AllColumnConfigurations = {
    [id: string]: ColumnConfiguration;
};

interface ConfigurationsState {
    allConfigurations: AllColumnConfigurations;
    allConfigurationsPrices: AllConfigurationsPrices;
    allFetchingConfigurationPriceIds: string[];
}

const initialState: ConfigurationsState = {
    allConfigurations: {},
    allConfigurationsPrices: {},
    allFetchingConfigurationPriceIds: [],
};

export default createReducer(initialState, (builder) => {
    builder.addCase(setAllConfigurations, (state, action) => {
        state.allConfigurations = action.payload;
    });

    builder.addCase(fetchColumnConfigurationPrice.pending, (state, action) => {
        state.allFetchingConfigurationPriceIds.push(action.meta.arg.configId);
    });

    builder.addCase(fetchColumnConfigurationPrice.fulfilled, (state, action) => {
        state.allConfigurationsPrices[action.meta.arg.configId] = action.payload.unitPriceWithChildItems || 0;

        state.allFetchingConfigurationPriceIds = state.allFetchingConfigurationPriceIds.filter(
            (id) => id !== action.meta.arg.configId,
        );
    });

    builder.addCase(fetchColumnConfigurationPrice.rejected, (state, action) => {
        state.allFetchingConfigurationPriceIds = state.allFetchingConfigurationPriceIds.filter(
            (id) => id !== action.meta.arg.configId,
        );
    });
});
