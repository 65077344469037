import { Action } from 'redux';
import {
    assignDoor,
    assignDoorHandleType,
    assignShelves,
    setColumns,
    setLed,
    setWardrobeType,
    setWoodColor,
} from '../../../../store/wardrobe/actions';
import { GetProjectResponse, ProjectResponseCustomOptionsValues, ProjectResponseNode } from '../../../../types/Project';
import { DoorHandleCode, isDoorHandleCode, isWardrobeCode, ProductCode } from '../../../products/domain/ProductCodes';
import { ChromeDoorHandleNode } from '../nodes/ChromeDoorHandleNode';
import { ColumnNode } from '../nodes/ColumnNode';
import { DoorNode } from '../nodes/DoorNode';
import { DoubleDrawerNode } from '../nodes/DoubleDrawerNode';
import { DrawerNode } from '../nodes/DrawerNode';
import { GlassShelveNode } from '../nodes/GlassShelveNode';
import { GoldDoorHandleNode } from '../nodes/GoldDoorHandleNode';
import { HangerFrontNode } from '../nodes/HangerFrontNode';
import { HangerNode } from '../nodes/HangerNode';
import { LedNode } from '../nodes/LedNode';
import { NodeWithProduct } from '../nodes/NodeWithProduct';
import { ShelveNode } from '../nodes/ShelveNode';
import { WardrobeNode } from '../nodes/WardrobeNode';
import { WardrobeWallNode } from '../nodes/WardrobeWallNode';
import { WoodDoorHandleNode } from '../nodes/WoodDoorHandleNode';
import { SomeNodeWithProduct } from '../visitor/domain';
import { StoreVisitor } from '../visitor/StoreVisitor';
import { AllProducts } from '../wardrobe';
import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { SpaceNode } from '../nodes/SpaceNode';

const MAP_INDEX_TO_WALL_NAME: {
    [k: number]: WardrobeWall;
} = {
    0: 'A',
    1: 'B',
    2: 'C',
};

const mapProductCodeToNodeConstructor: Partial<{ [key in ProductCode]: new (params: any) => NodeWithProduct<any> }> = {
    wardrobe: WardrobeNode,
    wardrobe_free_standing: WardrobeNode,
    l_shaped_wardrobe_left: WardrobeNode,
    l_shaped_wardrobe_right: WardrobeNode,
    u_shaped_wardrobe: WardrobeNode,
    wardrobe_wall: WardrobeWallNode,
    door: DoorNode,
    door_handle_chrome: ChromeDoorHandleNode,
    door_handle_gold: GoldDoorHandleNode,
    door_handle_wood: WoodDoorHandleNode,
    double_closet: ColumnNode,
    double_drawer: DrawerNode,
    glass_shelve: GlassShelveNode,
    hanger: HangerNode,
    hanger_front: HangerFrontNode,
    quad_drawer: DoubleDrawerNode,
    shelve: ShelveNode,
    single_closet: ColumnNode,
    door_glass: DoorNode,
    led: LedNode,
    spacing: SpaceNode,
};

export class WardrobeBuilderFromProject {
    products: AllProducts;

    constructor(products: AllProducts) {
        this.products = products;
    }

    getWardrobeType(project: GetProjectResponse) {
        const type = project.mainItem.product.replace('/api/v2/shop/products/', '');
        const finalType = isWardrobeCode(type) ? type : 'wardrobe';

        return finalType;
    }

    getWardrobe(project: GetProjectResponse) {
        const params = this.getParamsFromOption(project.mainItem.threeDParametersValues);
        const finalType = this.getWardrobeType(project);

        const wardrobe = new WardrobeNode({
            ...params,
            product: this.products[finalType],
        } as any);

        project.mainItem.childItems.forEach((projectNode) => {
            this.createNodeWithChildren(projectNode, wardrobe);
        });

        const walls = wardrobe.findChildrenByInstance([WardrobeWallNode]);

        walls.forEach((wall, wallIndex) => {
            const columns = wall.findChildrenByInstance([ColumnNode]);
            const indexWithOffset = finalType === 'l_shaped_wardrobe_right' ? wallIndex + 1 : wallIndex;

            columns.forEach((column) => {
                column.setParam('wall', MAP_INDEX_TO_WALL_NAME[indexWithOffset] || 'C');
            });
        });

        return wardrobe;
    }

    private getParamsFromOption(optionsValues: ProjectResponseCustomOptionsValues[]): { [key: string]: any } {
        return optionsValues.reduce<{ [key: string]: any }>((acc, { threeDParameter, value }) => {
            const key = threeDParameter.genericCode?.toLowerCase();

            if (key) {
                if (typeof value !== 'string') {
                    return acc;
                }
                const num = parseFloat(value);

                if (!Number.isNaN(num)) {
                    acc[key] = num;
                } else {
                    acc[key] = value;
                }
            }

            return acc;
        }, {});
    }

    private getProductByIri(productIri: string) {
        return Object.values(this.products).find((product) => product.path === productIri);
    }

    private createNodeWithChildren(projectNode: ProjectResponseNode, parent: SomeNodeWithProduct) {
        const node = this.createNodeByProduct(projectNode);

        parent.addChild(node);

        projectNode.childItems.forEach((projectNode) => {
            this.createNodeWithChildren(projectNode, node);
        });
        const column = parent instanceof ColumnNode ? parent : parent.findParentByInstance(ColumnNode);

        if (node instanceof DoorNode && column) {
            const { position, product } = node.getParams();

            column.setParam('doorProduct', product);
            column.setParam('position', position);
        }

        if (
            column &&
            (node instanceof ChromeDoorHandleNode ||
                node instanceof WoodDoorHandleNode ||
                node instanceof GoldDoorHandleNode)
        ) {
            const doorHandleTypeCode = node.getProduct().code;

            if (isDoorHandleCode(doorHandleTypeCode)) {
                column.setParam('doorHandleType', doorHandleTypeCode);
            } else {
                column.setParam('doorHandleType', null);
            }
        }

        return node;
    }

    createNodeByProduct(projectNode: ProjectResponseNode): SomeNodeWithProduct {
        const params = this.getParamsFromOption(projectNode.threeDParametersValues);
        const product = this.getProductByIri(projectNode.product);

        if (!product) {
            throw new Error(`Product ${projectNode.product} not found`);
        }
        const { code } = product;
        const NodeConstructor = mapProductCodeToNodeConstructor[code];

        if (!NodeConstructor) {
            throw new Error(`Product code '${code}' is not support`);
        }

        if (NodeConstructor === ColumnNode) {
            params.doorProduct = null;
        }

        return new NodeConstructor({
            ...params,
            product,
            layout: projectNode.layout || null,
        });
    }

    getActionForStore(project: GetProjectResponse) {
        const initialReduxActions: Action[] = [];
        const wardrobeNode = this.getWardrobe(project);
        const wardrobeType = wardrobeNode.getParams().product.code;

        if (isWardrobeCode(wardrobeType)) {
            initialReduxActions.push(setWardrobeType(wardrobeType));
        }

        const visitor = new StoreVisitor();
        const { doors, columns, led } = visitor.visitWardrobeNode(wardrobeNode);
        let handlerCode: DoorHandleCode | null = null;

        if (wardrobeNode.findChildrenByInstance([GoldDoorHandleNode]).length > 0) {
            handlerCode = 'door_handle_gold';
        } else if (wardrobeNode.findChildrenByInstance([WoodDoorHandleNode]).length > 0) {
            handlerCode = 'door_handle_wood';
        } else if (wardrobeNode.findChildrenByInstance([ChromeDoorHandleNode]).length > 0) {
            handlerCode = 'door_handle_chrome';
        }

        const availableHandles = handlerCode !== null ? [handlerCode] : [];

        initialReduxActions.push(assignDoor({ door: doors || null, availableHandles }));

        initialReduxActions.push(assignDoorHandleType(handlerCode));

        initialReduxActions.push(setColumns(columns || []));

        initialReduxActions.push(
            assignShelves({
                shelveType:
                    wardrobeNode.findChildrenByInstance([GlassShelveNode]).length > 0 ? 'glass_shelve' : 'shelve',
            }),
        );

        const { material } = wardrobeNode.getParams();

        if (material) {
            initialReduxActions.push(setWoodColor(material));
        }

        if (typeof led === 'boolean') {
            initialReduxActions.push(setLed(led));
        }

        return initialReduxActions;
    }
}
