import { splitNumberValueToArray } from './splitNumberValueToArray';

interface ChangeWidthValueByIndexParams {
    min: number;
    max: number;
    value: number;
    index: number;
    current: number[];
}
export const changeWidthValueByIndex = ({
    min,
    max,
    index,
    value,
    current,
}: ChangeWidthValueByIndexParams): number[] => {
    if (current.length === 1) {
        return [value];
    }
    const currentValue = current[index];

    if (currentValue === undefined) {
        return current;
    }

    let diff = Math.round((value - currentValue) / 10) * 10;
    const result = [...current];

    while (diff !== 0) {
        const copy = [...result];

        if (diff > 0) {
            copy[index] = 0;
            const widestIndex = result.findIndex(
                (value, loopIndex) => index !== loopIndex && Math.max(...copy) === value,
            );

            result[widestIndex] -= 10;
            diff -= 10;
        } else {
            copy[index] = max;
            const narrowIndex = result.findIndex(
                (value, loopIndex) => index !== loopIndex && Math.min(...copy) === value,
            );

            result[narrowIndex] += 10;
            diff += 10;
        }
    }

    result[index] = value;

    if (result.some((width) => width > max)) {
        return changeWidthValueByIndex({
            min,
            max,
            index,
            value,
            current: splitNumberValueToArray({
                min,
                max,
                length: current.length + 1,
                value: current.reduce((acc, n) => n + acc, 0),
            }),
        });
    }

    if (result.some((width) => width < min)) {
        const sum = current.reduce((acc, n) => n + acc, 0);
        const valueForCalculateMinimalNumberOfColumn = sum - value;

        if (valueForCalculateMinimalNumberOfColumn < min) {
            return [...current];
        }

        const minimalNumberOfColumn = splitNumberValueToArray({
            min,
            max,
            length: 1,
            value: valueForCalculateMinimalNumberOfColumn,
        });

        if (index > minimalNumberOfColumn.length) {
            return [...current];
        }

        return changeWidthValueByIndex({
            min,
            max,
            index,
            value,
            current: splitNumberValueToArray({
                min,
                max,
                length: current.length - 1,
                value: sum,
            }),
        });
    }

    return result;
};
