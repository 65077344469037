import { Product } from '../../../../types/Product';
import { WardrobeTreeVisitor } from '../visitor/domain';
import { ColumnNode } from './ColumnNode';
import { NodeWithProduct } from './NodeWithProduct';

interface HangerNodeParams {
    product: Product;
    box_height: number;
    width?: number;
}

export class HangerNode extends NodeWithProduct<HangerNodeParams> {
    getParams(): HangerNodeParams {
        const columNode = this.findParentByInstance(ColumnNode);

        if (columNode === null) {
            return super.getParams();
        }

        const columnWidth = columNode.getParams().width;

        return {
            ...super.getParams(),
            width: columnWidth,
        };
    }

    visit<T extends WardrobeTreeVisitor<any>>(visitor: T): ReturnType<T['visitHangerNode']> {
        return visitor.visitHangerNode(this);
    }
}
