import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { WardrobeColumn } from './reducer';

const WARDROBE_WALL_ORDER: { [key in WardrobeWall]: number } = {
    A: 1,
    B: 2,
    C: 3,
};

export function sortColumnByWallName(columns: WardrobeColumn[]) {
    return columns.sort((a, b) => WARDROBE_WALL_ORDER[a.wall] - WARDROBE_WALL_ORDER[b.wall]);
}
