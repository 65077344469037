import { getPublicEnv } from '../env/env';
import { fetchUnAuthorizationApi } from '../fetch/fetch';
import { getNumber, isObjectWithKey } from '../validateData';
import { FileType } from '../../types/Project';
import { IRI } from '../fetch/domain';

export const uploadFile = async (file: File, type: FileType): Promise<IRI> => {
    const body = new FormData();
    const apiUrl = await getPublicEnv('NEXT_PUBLIC_API_URL');

    body.append('file', file);
    body.append('type', type);

    const responseData = await fetchUnAuthorizationApi(`${apiUrl}/shop/project-item-files`, {
        method: 'POST',
        headers: {
            accept: 'application/json',
        },
        body,
    });

    if (!isObjectWithKey(responseData)) {
        throw new Error('Invalid response');
    }

    return `/api/v2/shop/project-item-files/${getNumber(responseData.id)}`;
};
