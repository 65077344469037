import { RootState } from '../../../store';
import { getProductByType, getSelectedDoorProductForColumn } from '../../../store/products/selectors';
import { getVisibleColumn } from '../../../store/wardrobe/selectors';
import { WardrobeColumn } from '../../../store/wardrobe/reducer';
import { ColumnConfiguration } from '../../../types/Configuration';
import { Product } from '../../../types/Product';
import { DoorHandlePositionCode, DOOR_HANDLE_POSITION_CODES } from '../../products/domain/ThreeDParameter';
import { availableProductCode, ProductCode } from '../../products/domain/ProductCodes';
import { WardrobeBuilder } from './builders/WardrobeBuilder';
import { WardrobeNode } from './nodes/WardrobeNode';
import { WrapperNode } from './nodes/WrapperNode';
import { ColumnNodeBuilder } from './builders/ColumnNodeBuilder';

export function isDoorHandlePositionCode(code: string): code is DoorHandlePositionCode {
    return DOOR_HANDLE_POSITION_CODES.includes(code as any);
}

export type AllProducts = { [type in Exclude<ProductCode, 'unknown'>]: Product };

const isAllProducts = (data: Partial<AllProducts>): data is AllProducts => {
    const {
        wardrobe,
        double_closet,
        single_closet,
        shelve,
        double_drawer,
        quad_drawer,
        door,
        door_handle_chrome,
        door_handle_gold,
        door_handle_wood,
        glass_shelve,
        hanger,
        hanger_front,
        door_glass,
        led,
        wardrobe_free_standing,
        wrapper,
        l_shaped_wardrobe_left,
        l_shaped_wardrobe_right,
        u_shaped_wardrobe,
        wardrobe_wall,
        spacing,
    } = data;

    return (
        wardrobe !== undefined &&
        double_closet !== undefined &&
        single_closet !== undefined &&
        shelve !== undefined &&
        double_drawer !== undefined &&
        quad_drawer !== undefined &&
        door !== undefined &&
        door_handle_chrome !== undefined &&
        door_handle_gold !== undefined &&
        door_handle_wood !== undefined &&
        glass_shelve !== undefined &&
        hanger !== undefined &&
        hanger_front !== undefined &&
        door_glass !== undefined &&
        wardrobe_free_standing !== undefined &&
        wrapper !== undefined &&
        led !== undefined &&
        l_shaped_wardrobe_left !== undefined &&
        l_shaped_wardrobe_right !== undefined &&
        u_shaped_wardrobe !== undefined &&
        wardrobe_wall !== undefined &&
        spacing !== undefined
    );
};

export const formatProducts = (products: Product[]): AllProducts | null => {
    const partialProduct = availableProductCode.reduce<Partial<AllProducts>>((acc, code) => {
        if (code === 'unknown') {
            return acc;
        }
        const product = products.find((product) => product.code === code);

        if (product) {
            acc[code] = product;
        }

        return acc;
    }, {});

    if (isAllProducts(partialProduct)) {
        return partialProduct;
    }

    return null;
};

export const getAllProducts = (state: RootState): AllProducts | null => formatProducts(state.products.allProducts);

export const createEmptyWrapper = (state: RootState) => {
    const product = getProductByType('wrapper')(state);

    if (!product) {
        throw new Error('wrapper product not found');
    }

    return new WrapperNode({
        product,
    });
};

let cachedCurrentWardrobe:
    | {
          result: WardrobeNode;
          columnRef: WardrobeColumn[];
          wardrobeType: ProductCode;
          led: boolean;
      }
    | undefined = undefined;

export const chooseDepthVariant = (config: ColumnConfiguration | undefined, depth: number) => {
    if (!config) {
        return null;
    }
    const depthRange = Object.keys(config || {})
        .map((v) => parseInt(v))
        .filter((n) => !Number.isNaN(n))
        .sort();

    const [firstDepth] = depthRange;
    const lastDepth = depthRange[depthRange.length - 1];

    if (firstDepth === undefined || lastDepth === undefined) {
        return null;
    }

    const depthKey = depthRange.find((v) => v >= depth);

    return config?.depthVariants[depthKey || lastDepth] || null;
};

export const createWardrobeStructureBasedOnState = (state: RootState) => {
    if (
        cachedCurrentWardrobe &&
        cachedCurrentWardrobe.columnRef === state.wardrobe.columns &&
        cachedCurrentWardrobe.led === state.wardrobe.led &&
        cachedCurrentWardrobe.wardrobeType === state.wardrobe.wardrobeType
    ) {
        return cachedCurrentWardrobe.result;
    }

    const products = getAllProducts(state);

    if (products === null) {
        return null;
    }

    const columns = getVisibleColumn(state);
    const [firstColumn] = columns;
    const { allConfigurations } = state.configurations;
    const wardrobeBuilder = new WardrobeBuilder(products, state.wardrobe.led, allConfigurations);
    const columnBuilder = new ColumnNodeBuilder(products, allConfigurations);

    columns.forEach((column, columnIndex) => {
        const depth = column.data.depth;

        if (!column.configurationId) {
            const columnNode = columnBuilder.buildColumn(column, state.wardrobe.led);

            wardrobeBuilder.addColumnNode(columnNode);

            return;
        }
        const selectedConfiguration = chooseDepthVariant(allConfigurations[column.configurationId], depth);

        if (!selectedConfiguration) {
            return;
        }

        const doorProduct = getSelectedDoorProductForColumn(state, columnIndex);

        wardrobeBuilder.addColumn({
            ...column,
            door: doorProduct,
        });
    });

    const result = wardrobeBuilder.getWardrobe(
        {
            material: firstColumn?.color || 'BLACK_WOOD',
        },
        state.wardrobe.wardrobeType,
    );

    cachedCurrentWardrobe = {
        columnRef: state.wardrobe.columns,
        wardrobeType: state.wardrobe.wardrobeType,
        led: state.wardrobe.led,
        result,
    };

    return result;
};
