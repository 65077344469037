import styled from 'styled-components';
import Breakpoint from '../../../../../../constants/Breakpoint.enum';
import { Color } from '../../../../../../constants/Color.enum';

export const EmptyCartWrapper = styled.div`
    padding: 0 28px 32px 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: ${Breakpoint.Tablet}) {
        padding: 0px;
    }
`;

export const EmptyCartWrapperPlaceholder = styled.div`
    height: 400px;
    background-color: ${Color.Background};
    width: 100%;
    border-radius: 10px;
    margin-bottom: 32px;
`;
