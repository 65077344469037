import React, { FC } from 'react';
import {
    CardVerticalWrapper,
    TopWrapper,
    ClearIconWrapperStyled,
    LoadingWrapper,
    MoreDetailsWrapper,
} from './CardVertical.styled';
import { Button } from '@formify/frontend-components';
import { ChevronRightIcon } from '../../../icons/ChevronRight/ChevronRight';
import { LinkXS, LinkS } from '@formify/frontend-components';
import { TextS } from '@formify/frontend-components';
import { SubtitleS, SubtitleXS } from '@formify/frontend-components';
import { ClearIcon } from '../../../icons/Clear/Clear';
import { LoadingIcon } from '../../../icons/Loading/Loading';
import { Color } from '../../../../constants/Color.enum';
import { TextLinesLimitWrapper } from '@formify/frontend-components/dist/components/Typography/Helpers';
import { useImageComponent } from '../../../ImageWithShimmerEffect/ImageContextComponent';
import { CartQuantityModifier } from '../../../CartQuantityModifier/CartQuantityModifier';
import { Tr } from '../../../Translations/Tr';
import { useCurrency } from '../../../Currency/CurrencyProvider';

export interface CardVerticalProps {
    itemId: number;
    subtitle?: string;
    title: string;
    price: number;
    oldPrice?: number;
    onClick?: () => void;
    onClickImage?: () => void;
    onRemove?: () => void;
    handleDetailsClick?: () => void;
    imgPath?: string;
    loading?: boolean;
    disabled?: boolean;
    dataTestId?: string;
    quantity: number;
}

export const CardVertical: FC<CardVerticalProps> = ({
    itemId,
    dataTestId,
    subtitle,
    title,
    onClick,
    onClickImage,
    onRemove,
    handleDetailsClick,
    imgPath,
    price,
    loading = false,
    disabled,
    oldPrice,
    quantity,
}) => {
    const Image = useImageComponent();
    const { formatPrice } = useCurrency();

    return (
        <CardVerticalWrapper data-test-id={dataTestId} onClick={onClick} disabled={disabled} isLoading={loading}>
            <TopWrapper>
                {onRemove && (
                    <ClearIconWrapperStyled
                        onClick={(e) => {
                            onRemove();
                            e.stopPropagation();
                        }}
                    >
                        <ClearIcon />
                    </ClearIconWrapperStyled>
                )}
                {imgPath && !loading && (
                    <div className={onClickImage ? 'cursor-pointer' : 'cursor-default'} onClick={onClickImage}>
                        <Image src={imgPath} alt={title} height={240} width={336} layout="fixed" objectFit="cover" />
                    </div>
                )}
                {loading && (
                    <LoadingWrapper>
                        <LoadingIcon />
                    </LoadingWrapper>
                )}
            </TopWrapper>
            <div className="flex justify-between p-20">
                <div className="flex flex-col flex-grow-1">
                    <TextLinesLimitWrapper maxLines={1}>
                        <LinkS className="text-left">{title}</LinkS>
                    </TextLinesLimitWrapper>
                    {subtitle && (
                        <TextLinesLimitWrapper maxLines={2}>
                            <TextS fontWeight={400}>{subtitle}</TextS>
                        </TextLinesLimitWrapper>
                    )}
                </div>
                {typeof price === 'number' && (
                    <div className="flex flex-col items-end ml-16">
                        <LinkXS>{formatPrice(price)}</LinkXS>
                        {oldPrice && (
                            <SubtitleXS color={Color.Error} className="line-through">
                                {formatPrice(oldPrice)}
                            </SubtitleXS>
                        )}
                    </div>
                )}
            </div>
            <div className="flex justify-between px-20 items-center mb-8">
                <div className="flex flex-col flex-grow-1">
                    <SubtitleS>
                        <Tr labelKey="quantity" defaultValue="Quantity" />
                    </SubtitleS>
                </div>
                <div className="flex flex-col items-end ml-16">
                    <CartQuantityModifier quantity={quantity} itemId={itemId} />
                </div>
            </div>
            {handleDetailsClick && (
                <MoreDetailsWrapper isLoading={loading} disabled={disabled}>
                    <Button
                        dataTestId="more_details_link"
                        variant="solid"
                        className="p-0 mb-24"
                        blocked={loading || disabled}
                        onClick={handleDetailsClick}
                    >
                        <div className="flex w-full -ml-8">
                            <SubtitleS>
                                <Tr labelKey="more_details" defaultValue="More Details" />
                            </SubtitleS>
                            <ChevronRightIcon />
                        </div>
                    </Button>
                </MoreDetailsWrapper>
            )}
        </CardVerticalWrapper>
    );
};
