import { Selector } from 'react-redux';
import type { RootState } from '../index';
import { INITIAL_COLUMN_VALUES } from '../../constants/products/Wardrobe';
import { Column } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { Product, WardrobeMeasurements } from '../../types/Product';
import { ColumnConfiguration } from '../../types/Configuration';
import { getConfiguration, getColumnProductCodeByWidth } from '../configurations/selectors';
import { getProductByType, getWardrobeDimensions, getAvailableMaterialCodeFromWardrobe } from '../products/selectors';
import { WardrobeColumn } from './reducer';
import { splitArrayBySelectedIndex } from '../utils';
import { ProductCode } from '../../services/products/domain/ProductCodes';
import { WARDROBE_FRAME_BOTTOM_HEIGHT_MM, WARDROBE_FRAME_THICKNESS_MM } from '../products/utils';
import {
    DoorHandlePositionCode,
    OptionSelect,
    ProductWoodenMaterialOption,
} from '../../services/products/domain/ThreeDParameter';
import { DoorNode } from '../../services/nodes/wardrobe/nodes/DoorNode';
import {
    chooseDepthVariant,
    createWardrobeStructureBasedOnState,
    getAllProducts,
} from '../../services/nodes/wardrobe/wardrobe';
import { formatMeasurements } from '../../components/Card/utils';
import { changeWidthValueByIndex } from '../../components/WardrobeWidth/helpers/changeWidthValueByIndex';
import { ColumnNode, ColumnNodeParams } from '../../services/nodes/wardrobe/nodes/ColumnNode';
import { splitNumberValueToArray } from '../../components/WardrobeWidth/helpers/splitNumberValueToArray';
import { ColumnNodeBuilder } from '../../services/nodes/wardrobe/builders/ColumnNodeBuilder';
import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { LanguageContextValues } from '../../components/Translations/LanguageProvider';
import { CloneNodeVisitor } from '../../services/nodes/wardrobe/visitor/CloneNodeVisitor';

export const getVisibleColumn: Selector<RootState, WardrobeColumn[]> = (state) =>
    state.wardrobe.columns.filter((column) => column.hidden !== true);

export const getVisibleColumnWall =
    (wall: WardrobeWall) =>
    (state: RootState): WardrobeColumn[] =>
        state.wardrobe.columns.filter((column) => column.hidden !== true && column.wall === wall);

export const countNumberOfVisibleColumnWall = (state: RootState): number => {
    let count = 0;

    if (getVisibleColumnWall('A')(state).length > 0) {
        count++;
    }

    if (getVisibleColumnWall('B')(state).length > 0) {
        count++;
    }

    if (getVisibleColumnWall('C')(state).length > 0) {
        count++;
    }

    return count;
};

export const getWallAtPosition =
    (index: number) =>
    // eslint-disable-next-line react/display-name
    (state: RootState): WardrobeWall | null => {
        let count = 0;
        const index1 = index + 1;

        if (getVisibleColumnWall('A')(state).length > 0) {
            count++;
        }
        if (count === index1) {
            return 'A';
        }

        if (getVisibleColumnWall('B')(state).length > 0) {
            count++;
        }
        if (count === index1) {
            return 'B';
        }

        if (getVisibleColumnWall('C')(state).length > 0) {
            count++;
        }

        if (count === index1) {
            return 'C';
        }

        return null;
    };

export const checkAlignHightVisible: Selector<RootState, boolean> = (state) => {
    const columns = getVisibleColumn(state);
    const baseHeight = columns[0]?.data.height;

    return columns.every(({ data }) => data.height === baseHeight);
};

export const getNumberOfVisibleColumns: Selector<RootState, number> = (state) => getVisibleColumn(state).length;

export const getLazyWardrobeWidth: Selector<RootState, number | null> = (state) => state.wardrobe.lazyWardrobeWidth;

export const getSelectedColumnProductCode: Selector<RootState, ProductCode | null> = (state) => {
    const selectedColumn = getSelectedColumn(state);

    if (selectedColumn === null) {
        return null;
    }

    return getColumnProductCodeByWidth(selectedColumn.data.width)(state);
};

// TODO: delete getPreparedSelectedColumn
export const getPreparedSelectedColumn: Selector<RootState, Column | null> = (state) => {
    const selectedColumnIndex = getSelectedColumnIndex(state);

    if (selectedColumnIndex === null) {
        return null;
    }

    return getPreparedColumnByIndex(selectedColumnIndex)(state);
};

// TODO: delete getPreparedColumnByIndex
const getPreparedColumnByIndex =
    (index: number) =>
    (state: RootState): Column | null => {
        const selectedColumn = state.wardrobe.columns[index] ?? null;

        const column: Column = {
            ...INITIAL_COLUMN_VALUES,
            ...selectedColumn?.data, // width, height
            ...selectedColumn, // wall
        };

        return column;
    };

export const getSelectedColumn: Selector<RootState, WardrobeColumn | null> = (state) =>
    typeof state.wardrobe.selectedColumnIndex === 'number'
        ? state.wardrobe.columns[state.wardrobe.selectedColumnIndex] ?? null
        : null;

export const checkSelectedColumnIsCorner = (state: RootState) => {
    const visibleWallA = getVisibleColumnWall('A')(state);
    const visibleWallB = getVisibleColumnWall('B')(state);
    const visibleWallC = getVisibleColumnWall('C')(state);
    const selectedColumn = getSelectedColumn(state);

    const isCorner =
        (selectedColumn === visibleWallB[0] && visibleWallA.length > 0) ||
        (selectedColumn === visibleWallB[visibleWallB.length - 1] && visibleWallC.length > 0);

    return isCorner;
};

export const getSelectedMaterialValue: Selector<RootState, ProductWoodenMaterialOption | null> = (state) =>
    typeof state.wardrobe.selectedColumnIndex === 'number'
        ? state.wardrobe.columns[state.wardrobe.selectedColumnIndex]?.color ?? null
        : state.wardrobe.columns[state.wardrobe.columns.length - 1]?.color || null;

export const getSelectedColumnIndex: Selector<RootState, number | null> = (state) => state.wardrobe.selectedColumnIndex;

export const getSelectedColumnWallIndex = (state: RootState): number | null => {
    const selectedIndex = state.wardrobe.selectedColumnIndex;

    if (selectedIndex === null) {
        return null;
    }

    let index = 0;
    const wall = state.wardrobe.columns[selectedIndex]?.wall;

    if (!wall) {
        return null;
    }

    while (state.wardrobe.columns[selectedIndex - index - 1]?.wall === wall) {
        index++;
    }

    return index;
};

export const getPreviousSelectedColumnIndex: Selector<RootState, number | null> = (state) => {
    const { previousSelectedColumnIndex } = state.wardrobe;
    const columns = getVisibleColumn(state);

    if (typeof previousSelectedColumnIndex === 'number' && columns[previousSelectedColumnIndex]) {
        return previousSelectedColumnIndex;
    }

    return null;
};

const countWardrobeMeasurements = (acc: WardrobeMeasurements, column: WardrobeColumn) => {
    acc.depth = Math.max(acc.depth, column.data.depth);
    acc.height = Math.max(acc.height, column.data.height);
    acc.width += column.data.width;

    return acc;
};

interface AdditionalSize {
    additionalWidth: number;
    additionalHeight: number;
}

export const getAdditionalFreeStandingFrameSize = (): AdditionalSize => {
    const additionalWidth = WARDROBE_FRAME_THICKNESS_MM * 2;
    const additionalHeight = WARDROBE_FRAME_BOTTOM_HEIGHT_MM;

    return { additionalWidth, additionalHeight };
};

const getAdditionalEmbedFrameSize = (): AdditionalSize => {
    const additionalWidth = 0;
    const additionalHeight = WARDROBE_FRAME_BOTTOM_HEIGHT_MM;

    return { additionalWidth, additionalHeight };
};

export const getAdditionalFrameSize = (state: RootState): AdditionalSize => {
    const wardrobeType = getWardrobeType(state);

    return wardrobeType === 'wardrobe_free_standing'
        ? getAdditionalFreeStandingFrameSize()
        : getAdditionalEmbedFrameSize();
};

export const getCurrentWardrobeMeasurements = (state: RootState): WardrobeMeasurements => {
    const columnSize = getVisibleColumn(state).reduce<WardrobeMeasurements>(countWardrobeMeasurements, {
        width: 0,
        height: 0,
        depth: 0,
    });

    return {
        width: columnSize.width,
        height: columnSize.height,
        depth: columnSize.depth,
    };
};

export const getCurrentWardrobeMeasurementsWall =
    (wall: WardrobeWall) =>
    (state: RootState): WardrobeMeasurements => {
        const columnSize = getVisibleColumnWall(wall)(state).reduce<WardrobeMeasurements>(countWardrobeMeasurements, {
            width: 0,
            height: 0,
            depth: 0,
        });

        return {
            width: columnSize.width,
            height: columnSize.height,
            depth: columnSize.depth,
        };
    };

const getSelectedColumnMeasurements = (state: RootState): WardrobeMeasurements | null => {
    const selectedColumn = getPreparedSelectedColumn(state);

    if (selectedColumn) {
        const { height, width, depth } = selectedColumn;

        return { height, width, depth };
    }

    return null;
};

export const getMeasurementsDescription =
    (wall: WardrobeWall, t: LanguageContextValues['t']) =>
    (state: RootState): string => {
        const selectedColumnMeasurements = getSelectedColumnMeasurements(state);

        if (selectedColumnMeasurements) {
            return formatMeasurements(selectedColumnMeasurements, t);
        }
        const { height, width, depth } = getCurrentWardrobeMeasurementsWall(wall)(state);
        const { additionalHeight, additionalWidth } = getAdditionalFrameSize(state);

        return formatMeasurements(
            {
                height: height + additionalHeight,
                width: width + additionalWidth,
                depth,
            },
            t,
        );
    };

const getSelectedConfiguration: Selector<RootState, ColumnConfiguration | null> = (state) => {
    const index = getSelectedConfigurationId(state);

    return index === null ? null : state.configurations.allConfigurations[index] || null;
};

export const getSelectedShelve: Selector<RootState, Product | undefined> = (state) => {
    const selectedColumn = getSelectedColumn(state);

    if (selectedColumn && selectedColumn.shelveType) {
        return getProductByType(selectedColumn.shelveType)(state);
    }

    return getProductByType('shelve')(state);
};

export const getSelectedShelveForWardrobe: Selector<RootState, Product | undefined> = (state) => {
    const shelvesCode = state.wardrobe.shelves;

    if (shelvesCode !== null) {
        return getProductByType(shelvesCode)(state);
    }

    return getProductByType('shelve')(state);
};

export const getSelectedDoorHandle: Selector<RootState, Product | null> = (state) => {
    const column =
        state.wardrobe.selectedColumnIndex === null
            ? state.wardrobe.columns[0]
            : state.wardrobe.columns[state.wardrobe.selectedColumnIndex];

    if (column?.doorHandleType) {
        return getProductByType(column.doorHandleType)(state) || null;
    }

    return null;
};

export const getSelectedDoorHandlePosition: Selector<RootState, DoorHandlePositionCode> = (state) => {
    const { selectedColumnIndex } = state.wardrobe;

    if (selectedColumnIndex !== null) {
        const selectedColumn = state.wardrobe.columns[selectedColumnIndex];

        return selectedColumn?.doorHandlePosition || 'RIGHT';
    }

    const [column] = getVisibleColumn(state);

    return column?.doorHandlePosition || 'RIGHT';
};

export const getSelectedConfigurationVariant: Selector<
    RootState,
    ColumnConfiguration['depthVariants'][number] | null
> = (state) => {
    const config = getSelectedConfiguration(state);
    const size = getSelectedColumnMeasurements(state);

    if (config && size) {
        const { depth } = size;

        return chooseDepthVariant(config, depth) || null;
    }

    return null;
};

export const getSelectedFrameMaterial: Selector<RootState, OptionSelect | null> = (state) => {
    const frameProducts = getAvailableMaterialCodeFromWardrobe(state);
    const materialValue = getSelectedMaterialValue(state);

    if (materialValue && frameProducts) {
        const selectedFrameMaterial = frameProducts.find((item) => item.value === materialValue);

        return selectedFrameMaterial || null;
    }

    return null;
};

export const getSelectedConfigurationId: Selector<RootState, string | null> = (state) => {
    const { selectedColumnIndex } = state.wardrobe;

    if (selectedColumnIndex !== null) {
        return state.wardrobe.columns[selectedColumnIndex]?.configurationId ?? null;
    }

    return null;
};

export const getConfigurationVariant =
    (id: string): Selector<RootState, ColumnConfiguration['depthVariants'][number] | null> =>
    (state) => {
        const config = getConfiguration(id)(state);
        const size = getSelectedColumnMeasurements(state);

        if (config && size) {
            const { depth } = size;

            return chooseDepthVariant(config, depth) || null;
        }

        return null;
    };

export const getWardrobeTotalPrice: Selector<RootState, number | null> = (state) => state.wardrobe.totalPrice;

export const checkWardrobeTotalPriceIsFetching: Selector<RootState, boolean> = (state) =>
    state.wardrobe.totalPricePending;

export const checkIfColumnAvailableForSplitActionAvailable = (state: RootState): boolean => {
    const preparedSelectedColumn = getPreparedSelectedColumn(state);
    const { singleClosetWidth } = getWardrobeDimensions(state);

    if (!singleClosetWidth || !preparedSelectedColumn) {
        return false;
    }
    const visibleColumns = getVisibleColumn(state);

    return preparedSelectedColumn.width >= singleClosetWidth.min * 2 || visibleColumns.length > 1;
};

const createColumnWithItems =
    (state: RootState) =>
    ([newColumnWithoutItems, oldColumn]: [ColumnNode, ColumnNode]) => {
        const products = getAllProducts(state);

        if (products === null) {
            return newColumnWithoutItems;
        }

        const builder = new ColumnNodeBuilder(products, state.configurations.allConfigurations);
        const id = newColumnWithoutItems.getConfigId();

        if (id === null) {
            const cloneNodeVisitor = new CloneNodeVisitor();

            oldColumn
                .visit(cloneNodeVisitor)
                .getChildren()
                .forEach((child: any) => {
                    newColumnWithoutItems.addChild(child);
                });

            return newColumnWithoutItems;
        }

        const config = getConfiguration(id)(state);

        if (config === null) {
            return newColumnWithoutItems;
        }
        const variant = chooseDepthVariant(config, newColumnWithoutItems.getParams().depth) || null;

        if (variant === null) {
            return newColumnWithoutItems;
        }

        const wardrobe = createWardrobeStructureBasedOnState(state);
        const led = wardrobe?.getParams()?.led || false;

        return builder.buildColumnByParam(newColumnWithoutItems.getParams(), variant, led);
    };

export const getSplittedWardrobeBySelectedColumn = (state: RootState): ColumnNode[] => {
    const selectedIndexAll = state.wardrobe.selectedColumnIndex;
    const wardrobe = createWardrobeStructureBasedOnState(state);
    const columnsAll = wardrobe?.findChildrenByInstance([ColumnNode]) || [];
    const products = getAllProducts(state);

    if (products === null) {
        return columnsAll;
    }

    const columnsProducts = [products.double_closet, products.single_closet];

    if (selectedIndexAll === null) {
        return columnsAll;
    }

    const selectedColumnAll = columnsAll[selectedIndexAll];

    if (selectedColumnAll === undefined) {
        return columnsAll;
    }

    const columns = columnsAll.filter((column) => column.getParams().wall === selectedColumnAll.getParams().wall);
    const selectedIndex = columns.findIndex((column) => column === selectedColumnAll);

    if (selectedIndex === -1) {
        return columnsAll;
    }

    const [beforeSelectedColumn, selectedColumn, afterSelectedColumn] = splitArrayBySelectedIndex(
        columns,
        selectedIndex,
    );

    const preparedSelectedColumn = getPreparedSelectedColumn(state);
    const { singleClosetWidth, doubleClosetWidth } = getWardrobeDimensions(state);

    if (
        selectedColumn === undefined ||
        preparedSelectedColumn === null ||
        singleClosetWidth === null ||
        doubleClosetWidth === null
    ) {
        return columns;
    }

    const width = Math.floor(preparedSelectedColumn.width / 2);
    const { min } = singleClosetWidth;

    const newRightColumn = ColumnNode.createColumnNode(columnsProducts, {
        ...selectedColumn.getParams(),
        width: Math.max(min, width),
        position: 'RIGHT',
    });

    const newLeftColumn = ColumnNode.createColumnNode(columnsProducts, {
        ...selectedColumn.getParams(),
        width: Math.max(min, width * 2 === preparedSelectedColumn.width ? width : width + 1),
        position: 'LEFT',
    });

    if (selectedColumn.getParams().layout === null) {
        const cloneVisitor = new CloneNodeVisitor();
        const children = selectedColumn.getChildren();

        children.forEach((child) => {
            newRightColumn.addChild(child.visit(cloneVisitor));
            newLeftColumn.addChild(child.visit(cloneVisitor));
        });
    }

    const result = [...beforeSelectedColumn, newRightColumn, newLeftColumn, ...afterSelectedColumn];

    const columnWidths =
        selectedColumn.getParams().wall === 'B'
            ? getWidthsForColumnB(result)(state)
            : getWidthsForColumn(result)(state);

    const addItemsToColumn = createColumnWithItems(state);

    if (columnWidths === null) {
        return columnsAll;
    }

    return [
        ...result
            .map((column, index): [ColumnNode, ColumnNode] => {
                const params: ColumnNodeParams = column.getParams();

                return [
                    ColumnNode.createColumnNode(columnsProducts, {
                        ...params,
                        width: columnWidths[index] || params.width,
                    }),
                    column,
                ];
            })
            .map(addItemsToColumn),
        ...columnsAll.filter((column) => column.getParams().wall !== selectedColumnAll.getParams().wall),
    ];
};

const getWidthsForColumnB = (result: ColumnNode[]) => (state: RootState) => {
    const { singleClosetWidth, doubleClosetWidth } = getWardrobeDimensions(state);

    if (singleClosetWidth === null || doubleClosetWidth === null) {
        return null;
    }

    const columnsTotalWidth = result.reduce((acc, column) => {
        const width = column.getParams().width;

        return acc + width;
    }, 0);

    const first = result[0]?.getParams().width || 1000;
    const last = result[result.length - 1]?.getParams().width || 1000;

    const columnWidths = splitNumberValueToArray({
        length: result.length - 2,
        value: Math.max(columnsTotalWidth - first - last, singleClosetWidth.min),
        max: doubleClosetWidth.max,
        min: singleClosetWidth.min,
    });

    return [first, ...columnWidths, last];
};

const getWidthsForColumn = (result: ColumnNode[]) => (state: RootState) => {
    const { singleClosetWidth, doubleClosetWidth } = getWardrobeDimensions(state);

    if (singleClosetWidth === null || doubleClosetWidth === null) {
        return null;
    }

    const columnsTotalWidth = result.reduce((acc, column) => {
        const width = column.getParams().width;

        return acc + width;
    }, 0);

    const columnWidths = splitNumberValueToArray({
        length: result.length,
        value: columnsTotalWidth,
        max: doubleClosetWidth.max,
        min: singleClosetWidth.min,
    });

    return columnWidths;
};

export const getRemoveColumnWardrobeBySelectedColumn = (state: RootState): ColumnNode[] => {
    const { doubleClosetWidth, singleClosetWidth } = getWardrobeDimensions(state);
    const selectedIndexAll = state.wardrobe.selectedColumnIndex;
    const wardrobe = createWardrobeStructureBasedOnState(state);
    const columnsAll = wardrobe?.findChildrenByInstance([ColumnNode]) || [];
    const products = getAllProducts(state);

    if (doubleClosetWidth === null || singleClosetWidth === null) {
        return columnsAll;
    }

    if (products === null) {
        return columnsAll;
    }

    if (selectedIndexAll === null) {
        return columnsAll;
    }

    const selectedColumnAll = columnsAll[selectedIndexAll];

    if (selectedColumnAll === undefined) {
        return columnsAll;
    }

    const columns = columnsAll.filter((column) => column.getParams().wall === selectedColumnAll.getParams().wall);

    const otherWallsColumns = columnsAll.filter(
        (column) => column.getParams().wall !== selectedColumnAll.getParams().wall,
    );

    const selectedIndex = columns.findIndex((column) => column === selectedColumnAll);

    if (selectedIndex === -1) {
        return columnsAll;
    }

    const [beforeSelectedColumn, selectedColumn, afterSelectedColumn] = splitArrayBySelectedIndex(
        columns,
        selectedIndex,
    );

    if (selectedColumn) {
        const { width: columnsTotalWidth } = getCurrentWardrobeMeasurementsWall(selectedColumn.getParams().wall)(state);
        const result = [...beforeSelectedColumn, ...afterSelectedColumn];

        const columnWidths = splitNumberValueToArray({
            length: result.length,
            value: columnsTotalWidth,
            max: doubleClosetWidth.max,
            min: singleClosetWidth.min,
        });

        const addItemsToColumn = createColumnWithItems(state);

        return [
            ...result
                .map((column, index): [ColumnNode, ColumnNode] => {
                    const params: ColumnNodeParams = column.getParams();

                    return [
                        ColumnNode.createColumnNode([products.double_closet, products.single_closet], {
                            ...params,
                            width: columnWidths[index] || params.width,
                        }),
                        column,
                    ];
                })
                .map(addItemsToColumn),
            ...otherWallsColumns,
        ];
    }

    return columns;
};
export const recalculateOtherColumnsIfMoreThanTwoDimensionsForWallB =
    (value: number, wall: WardrobeWall) =>
    (state: RootState): number[] => {
        const { singleClosetWidth, doubleClosetWidth } = getWardrobeDimensions(state);
        const visibleColumn = getVisibleColumnWall(wall)(state);
        const selectedColumnIndex = getSelectedColumnWallIndex(state);
        const oldSize = visibleColumn.map((column) => column.data.width);

        if (!doubleClosetWidth || !singleClosetWidth || selectedColumnIndex === null) {
            return oldSize;
        }

        if (selectedColumnIndex === 0) {
            const lastOldSize = oldSize[oldSize.length - 1] as number;

            const newSizes = changeWidthValueByIndex({
                max: doubleClosetWidth.max,
                min: singleClosetWidth.min,
                value: value,
                index: 0,
                current: oldSize.slice(0, -1),
            });

            return [...newSizes, lastOldSize];
        } else if (selectedColumnIndex === oldSize.length - 1) {
            const firstOldSize = oldSize[0] as number;

            const newSizes = changeWidthValueByIndex({
                max: doubleClosetWidth.max,
                min: singleClosetWidth.min,
                value: value,
                index: 0,
                current: oldSize.slice(1).reverse(),
            });

            return [firstOldSize, ...newSizes.reverse()];
        }

        const firstOldSize = oldSize[0] as number;
        const lastOldSize = oldSize[oldSize.length - 1] as number;

        const newSizes = changeWidthValueByIndex({
            max: doubleClosetWidth.max,
            min: singleClosetWidth.min,
            value: value,
            index: selectedColumnIndex - 1,
            current: oldSize.slice(1, -1),
        });

        return [firstOldSize, ...newSizes, lastOldSize];
    };

export const recalculateOtherColumnsIfMoreThanTwoDimensions =
    (value: number, wall: WardrobeWall) =>
    (state: RootState): number[] => {
        const { singleClosetWidth, doubleClosetWidth } = getWardrobeDimensions(state);
        const visibleColumn = getVisibleColumnWall(wall)(state);
        const selectedColumnIndex = getSelectedColumnWallIndex(state);
        const oldSize = visibleColumn.map((column) => column.data.width);

        if (!doubleClosetWidth || !singleClosetWidth || selectedColumnIndex === null) {
            return oldSize;
        }

        const newSizes = changeWidthValueByIndex({
            max: doubleClosetWidth.max,
            min: singleClosetWidth.min,
            value,
            index: selectedColumnIndex,
            current: oldSize,
        });

        return newSizes;
    };

export const getNumberOfDoorsInCurrentWardrobe = (state: RootState): number => {
    const wardrobe = createWardrobeStructureBasedOnState(state);

    if (wardrobe === null) {
        throw new Error('Wardrobe is not created');
    }
    const children = wardrobe.findChildrenByInstance([DoorNode]);

    return children.length;
};

export const getWardrobeLed = (state: RootState): boolean => state.wardrobe.led;

export const getWardrobeType = (state: RootState): ProductCode => state.wardrobe.wardrobeType;
