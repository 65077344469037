import { convertValueFromMmToCm } from '../../store/products/utils';
import { CartCustomOptionsValue, CartItem } from '../../types/Cart';
import { WardrobeMeasurements } from '../../types/Product';
import { LanguageContextValues } from '../Translations/LanguageProvider';

const mapOptionToLabel = (
    acc: WardrobeMeasurements,
    { threeDParameter, value }: CartCustomOptionsValue,
): WardrobeMeasurements => {
    if (threeDParameter.genericCode === 'HEIGHT') {
        return { ...acc, height: Number(value) };
    }
    if (threeDParameter.genericCode === 'WIDTH') {
        return { ...acc, width: Number(value) };
    }
    if (threeDParameter.genericCode === 'DEPTH') {
        return { ...acc, depth: Number(value) };
    }

    return acc;
};

export const formatMeasurements = (
    { width, height, depth }: Partial<WardrobeMeasurements>,
    t: LanguageContextValues['t'],
) => {
    const parts: string[] = [];

    if (typeof width === 'number' && !Number.isNaN(width)) {
        parts.push(`${t('short_width', 'W ').replace(' ', '')}${convertValueFromMmToCm(width)}`);
    }

    if (typeof height === 'number' && !Number.isNaN(height)) {
        parts.push(`${t('short_height', 'H ').replace(' ', '')}${convertValueFromMmToCm(height)}`);
    }

    if (typeof depth === 'number' && !Number.isNaN(depth)) {
        parts.push(`${t('short_depth', 'D ').replace(' ', '')}${convertValueFromMmToCm(depth)}`);
    }

    if (parts.length === 0) {
        return '';
    }

    return `${parts.join(' / ')} cm`;
};

export const getCartItemSubtitle = (item: CartItem, t: LanguageContextValues['t']) => {
    const measurements = item.threeDParametersValues.reduce<WardrobeMeasurements>(mapOptionToLabel, {
        width: NaN,
        height: NaN,
        depth: NaN,
    });

    return formatMeasurements(measurements, t);
};
