import { ColumnNode } from '../nodes/ColumnNode';
import { DoorNode } from '../nodes/DoorNode';
import { DoubleDrawerNode } from '../nodes/DoubleDrawerNode';
import { DrawerNode } from '../nodes/DrawerNode';
import { GlassShelveNode } from '../nodes/GlassShelveNode';
import { HangerFrontNode } from '../nodes/HangerFrontNode';
import { HangerNode } from '../nodes/HangerNode';
import { ShelveNode } from '../nodes/ShelveNode';
import { SpaceNode } from '../nodes/SpaceNode';
import { WardrobeNode } from '../nodes/WardrobeNode';
import { Node } from '../../common/nodes/Node';
import { WardrobeTreeVisitor } from './domain';
import { WoodDoorHandleNode } from '../nodes/WoodDoorHandleNode';
import { ChromeDoorHandleNode } from '../nodes/ChromeDoorHandleNode';
import { GoldDoorHandleNode } from '../nodes/GoldDoorHandleNode';
import { LedNode } from '../nodes/LedNode';
import { WrapperNode } from '../nodes/WrapperNode';
import { WardrobeWallNode } from '../nodes/WardrobeWallNode';
import { HangerFrontWithShelveNode } from '../nodes/HangerFrontWithShelveNode';

export class CloneNodeVisitor
    implements
        WardrobeTreeVisitor<
            ShelveNode,
            WardrobeNode,
            HangerFrontNode,
            HangerNode,
            GlassShelveNode,
            DrawerNode,
            DoubleDrawerNode,
            ColumnNode,
            DoorNode,
            SpaceNode,
            WoodDoorHandleNode,
            ChromeDoorHandleNode,
            GoldDoorHandleNode,
            LedNode,
            WrapperNode,
            WardrobeWallNode,
            HangerFrontWithShelveNode
        >
{
    visitShelveNode(node: ShelveNode): ShelveNode {
        const clone = new ShelveNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitWardrobeNode(node: WardrobeNode): WardrobeNode {
        const clone = new WardrobeNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitWrapperNode(node: WrapperNode): WrapperNode {
        const clone = new WrapperNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitHangerFrontNode(node: HangerFrontNode): HangerFrontNode {
        const clone = new HangerFrontNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitSpaceNode(node: SpaceNode): SpaceNode {
        const clone = new SpaceNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitHangerNode(node: HangerNode): HangerNode {
        const clone = new HangerNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitGlassShelveNode(node: GlassShelveNode): GlassShelveNode {
        const clone = new GlassShelveNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitDrawerNode(node: DrawerNode): DrawerNode {
        const clone = new DrawerNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitDoubleDrawerNode(node: DoubleDrawerNode): DoubleDrawerNode {
        const clone = new DoubleDrawerNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitColumnNode(node: ColumnNode): ColumnNode {
        const clone = new ColumnNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitDoorNode(node: DoorNode): DoorNode {
        const clone = new DoorNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitChildrenNode<T extends Node<any>>(node: T): Node<any>[] {
        return node.getChildren().map((node) => node.visit(this));
    }

    visitWoodDoorHandleNode(node: WoodDoorHandleNode): WoodDoorHandleNode {
        const clone = new WoodDoorHandleNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitChromeDoorHandleNode(node: ChromeDoorHandleNode): ChromeDoorHandleNode {
        const clone = new ChromeDoorHandleNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitGoldDoorHandleNode(node: GoldDoorHandleNode): GoldDoorHandleNode {
        const clone = new GoldDoorHandleNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitLedNode(node: LedNode): LedNode {
        const clone = new LedNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitWardrobeWallNode(node: WardrobeWallNode): WardrobeWallNode {
        const clone = new WardrobeWallNode(node.getParams());

        clone.addChildren(this.visitChildrenNode(node));

        return clone;
    }

    visitHangerFrontWithShelve(node: HangerFrontWithShelveNode): HangerFrontWithShelveNode {
        const hangerClone = node.hanger.visit(this);
        const shelveClone = node.shelve.visit(this);
        const clone = new HangerFrontWithShelveNode(hangerClone, shelveClone);

        return clone;
    }
}
