import { AllColumnConfigurations } from '../../../../store/configurations/reducer';
import { WARDROBE_FRAME_BOTTOM_HEIGHT_MM, WARDROBE_FRAME_THICKNESS_MM } from '../../../../store/products/utils';
import { WardrobeColumn } from '../../../../store/wardrobe/reducer';
import { WardrobeCode } from '../../../products/domain/ProductCodes';
import { ColumnNode } from '../nodes/ColumnNode';
import { WardrobeNode, WardrobeNodeParams } from '../nodes/WardrobeNode';
import { WardrobeWallNode } from '../nodes/WardrobeWallNode';
import { AllProducts } from '../wardrobe';
import { ColumnNodeBuilder } from './ColumnNodeBuilder';
import { DoorsBuilder } from './DoorsBuilder';

export class WardrobeBuilder {
    products: AllProducts;
    columnBuilder: ColumnNodeBuilder;
    doorsBuilder: DoorsBuilder;
    allConfigurations: AllColumnConfigurations;
    columns: ColumnNode[];
    led: boolean;

    constructor(products: AllProducts, led: boolean, allConfigurations: AllColumnConfigurations) {
        this.columnBuilder = new ColumnNodeBuilder(products, allConfigurations);
        this.doorsBuilder = new DoorsBuilder(products);
        this.columns = [];
        this.products = products;
        this.allConfigurations = allConfigurations;
        this.led = led;
    }

    addColumn(columnData: WardrobeColumn) {
        const column = this.columnBuilder.buildColumn(columnData, this.led);

        this.addColumnNode(column);
    }

    addColumnNode(column: ColumnNode) {
        this.columns.push(column);
    }

    gerWardrobeWall() {
        return this.columns.reduce<[ColumnNode[], ColumnNode[], ColumnNode[]]>(
            (acc, column) => {
                const { wall } = column.getParams();

                if (wall === 'A') {
                    acc[0].push(column);
                }

                if (wall === 'B') {
                    acc[1].push(column);
                }

                if (wall === 'C') {
                    acc[2].push(column);
                }

                return acc;
            },
            [[], [], []],
        );
    }

    getWardrobe(
        params: Omit<WardrobeNodeParams, 'product' | 'width' | 'height' | 'depth' | 'led' | 'legs_number'>,
        wardrobeType: WardrobeCode,
    ) {
        const width = this.columns.reduce<number>((acc, column) => acc + column.getParams().width, 0);
        const height = Math.max(...this.columns.map<number>((column) => column.getParams().height));
        const depth = Math.max(...this.columns.map<number>((column) => column.getParams().depth));

        const wardrobe = new WardrobeNode({
            ...params,
            depth,
            width: wardrobeType === 'wardrobe_free_standing' ? width + WARDROBE_FRAME_THICKNESS_MM * 2 : width,
            height: height + WARDROBE_FRAME_BOTTOM_HEIGHT_MM,
            product: this.products[wardrobeType],
            led: this.led,
        });

        const [wallAColumns, wallBColumns, wallCColumns] = this.gerWardrobeWall();

        if (wallAColumns.length > 0) {
            const wallANode = this.createWall(wallAColumns);

            this.addDoors(wallANode);
            wardrobe.addChild(wallANode);
        }

        if (wallBColumns.length > 0) {
            const wallBNode = this.createWall(wallBColumns);
            const lastColumnA = wallAColumns[wallAColumns.length - 1];
            const depthA = this.countDepthWithDoors(lastColumnA);
            const firstColumnC = wallCColumns[0];
            const depthC = this.countDepthWithDoors(firstColumnC);

            this.addDoors(wallBNode, depthA, depthC);
            wardrobe.addChild(wallBNode);
        }

        if (wallCColumns.length > 0) {
            const wallCNode = this.createWall(wallCColumns);

            this.addDoors(wallCNode);
            wardrobe.addChild(wallCNode);
        }

        return wardrobe;
    }

    createWall(columns: ColumnNode[]): WardrobeWallNode {
        const wall = new WardrobeWallNode({
            product: this.products.wardrobe_wall,
        });

        wall.addChildren(columns);

        return wall;
    }

    addDoors(wall: WardrobeWallNode, offsetLeft = 0, offsetRight = 0) {
        const columns = wall.findChildrenByInstance([ColumnNode]);

        columns.forEach((column, index) => {
            const { doorProduct, position, doorHandleType, width } = column.getParams();

            if (doorProduct !== null) {
                const glass = doorProduct.code === 'door_glass';

                if (offsetLeft !== 0 && index === 0) {
                    column.addChild(
                        this.doorsBuilder.buildSingleDoors(column, 'LEFT', glass, doorHandleType, width - offsetLeft),
                    );

                    return;
                }

                if (offsetRight !== 0 && index === columns.length - 1) {
                    column.addChild(
                        this.doorsBuilder.buildSingleDoors(column, 'RIGHT', glass, doorHandleType, width - offsetRight),
                    );

                    return;
                }

                const doors =
                    position === 'DOUBLE'
                        ? this.doorsBuilder.buildDoubleDoors(column, position, glass, doorHandleType)
                        : this.doorsBuilder.build(column, position, glass, doorHandleType);

                column.addChildren(doors);
            }
        });
    }

    countDepthWithDoors(column: ColumnNode | undefined) {
        if (!column) {
            return 0;
        }

        const { depth, doorProduct } = column.getParams();

        if (doorProduct === null) {
            return depth;
        }

        return depth + (doorProduct.code === 'door_glass' ? 20 : 18);
    }
}
