import { Product } from '../../../../types/Product';
import { WardrobeTreeVisitor } from '../visitor/domain';
import { NodeWithProduct } from './NodeWithProduct';

interface WrapperNodeParams {
    product: Product;
}

export class WrapperNode extends NodeWithProduct<WrapperNodeParams> {
    visit<T extends WardrobeTreeVisitor<any>>(visitor: T): ReturnType<T['visitWrapperNode']> {
        return visitor.visitWrapperNode(this);
    }
}
