import { Product } from '../../../../types/Product';
import { ThreeDParameter } from '../../../products/domain/ThreeDParameter';
import { WardrobeTreeVisitor } from '../visitor/domain';
import { ColumnNode } from './ColumnNode';
import { NodeWithProduct } from './NodeWithProduct';

interface HangerFrontNodeParams {
    product: Product;
    box_height: number;
    width?: number;
    numberOfElement?: number;
}

const DEFAULT_HANGERS_NUMBER = 1;

export class HangerFrontNode extends NodeWithProduct<HangerFrontNodeParams> {
    widthOption: ThreeDParameter | undefined;

    constructor(params: HangerFrontNodeParams) {
        super(params);
        this.widthOption = params.product.threeDParameters.find((option) => option.genericCode === 'WIDTH');
    }

    getParams() {
        const columNode = this.findParentByInstance(ColumnNode);

        if (columNode === null) {
            return super.getParams();
        }

        const columnWidth = columNode.getParams().width;

        if (this.widthOption === undefined) {
            return super.getParams();
        }

        if (this.widthOption.type === 'INT') {
            const numberOfElement =
                columnWidth > this.widthOption.intValidation.max
                    ? Math.ceil(columnWidth / this.widthOption.intValidation.max)
                    : DEFAULT_HANGERS_NUMBER;

            return {
                ...super.getParams(),
                width: columnWidth / numberOfElement,
                numberOfElement,
            };
        }

        return super.getParams();
    }

    getNumberOfHanger() {
        return this.getParams().numberOfElement || DEFAULT_HANGERS_NUMBER;
    }

    visit<T extends WardrobeTreeVisitor<any>>(visitor: T): ReturnType<T['visitHangerFrontNode']> {
        return visitor.visitHangerFrontNode(this);
    }
}
