import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { Product } from '../../../../types/Product';
import { DoorHandlePositionCode, ProductWoodenMaterialOption } from '../../../products/domain/ThreeDParameter';
import { DoorHandleCode, ShelveCode } from '../../../products/domain/ProductCodes';
import { WardrobeTreeVisitor } from '../visitor/domain';
import { NodeWithProduct } from './NodeWithProduct';

export interface ColumnNodeParams {
    product: Product;
    width: number;
    height: number;
    depth: number;
    material: ProductWoodenMaterialOption;
    shelveType: ShelveCode;
    doorProduct: Product | null;
    doorHandleType: DoorHandleCode | null;
    position: DoorHandlePositionCode | 'DOUBLE';
    layout: string | null;
    wall: WardrobeWall;
}

export class ColumnNode extends NodeWithProduct<ColumnNodeParams> {
    constructor(params: ColumnNodeParams) {
        super(params);
    }

    visit<T extends WardrobeTreeVisitor<any>>(visitor: T): any {
        return visitor.visitColumnNode(this);
    }

    isDouble() {
        return this.getProduct().code === 'double_closet';
    }

    getConfigId() {
        const { layout } = this.getParams();

        return layout && layout.replace('/api/v2/shop/layouts/', '');
    }

    static shouldBeDouble(columnProducts: Product[], params: Omit<ColumnNodeParams, 'product'>) {
        return ColumnNode.createColumnNode(columnProducts, params).getProduct().code === 'double_closet';
    }

    static createColumnNode(columnProducts: Product[], params: Omit<ColumnNodeParams, 'product'>): ColumnNode {
        for (let i = 0; i < columnProducts.length; i++) {
            const product = columnProducts[i];

            if (!product) {
                continue;
            }

            const column = new ColumnNode({
                ...params,
                product,
            });

            if (column.validateParamValue('width', params.width)) {
                return column;
            }
        }
        throw new Error('Not found product');
    }

    getNumberOfLegs(): 5 | 4 {
        const { width } = this.getParams();

        return ColumnNode.getNumberOfLegs(width);
    }

    static getNumberOfLegs(width: number): 5 | 4 {
        return width > 750 ? 5 : 4;
    }
}
