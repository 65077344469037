import { Product } from '../../../../types/Product';
import { ProductWoodenMaterialOption } from '../../../products/domain/ThreeDParameter';
import { WardrobeTreeVisitor } from '../visitor/domain';
import { ColumnNode } from './ColumnNode';
import { NodeWithProduct } from './NodeWithProduct';

export interface WardrobeNodeParams {
    product: Product;
    width: number;
    height: number;
    depth: number;
    material?: ProductWoodenMaterialOption;
    led: boolean;
}

interface WardrobeNodeCalculatedParams extends WardrobeNodeParams {
    legs_number: number;
}

export class WardrobeNode extends NodeWithProduct<WardrobeNodeParams> {
    getParams(): WardrobeNodeCalculatedParams {
        const params = super.getParams();
        const columns = this.findChildrenByInstance([ColumnNode]);
        const legs_number = columns.reduce((sum, column) => sum + column.getNumberOfLegs(), 0);
        const [firstColumn] = columns;

        return {
            ...params,
            material: params.material ? params.material : firstColumn?.getParams().material,
            legs_number: legs_number,
        };
    }

    visit<T extends WardrobeTreeVisitor<any>>(visitor: T): ReturnType<T['visitWardrobeNode']> {
        return visitor.visitWardrobeNode(this);
    }
}
