import React, { FC, useEffect, useState } from 'react';
import { CartItem } from '../../../../../../types/Cart';
import { getProjectImageByType } from '../../../../../../services/project/project';
import { ScrollableContentInSidePanel } from '../../../../../Sidebar/components/ScrollableContentInSidePanel/ScrollableContentInSidePanel';
import { Button } from '@formify/frontend-components';
import { CardHorizontal } from '../../../../../Card/components/CardHorizontal/CardHorizontal';
import { getProjectIdFromProject } from '../../utils';
import { WardrobeNodeParams } from '../../../../../../services/nodes/wardrobe/nodes/WardrobeNode';
import { formatMeasurements } from '../../../../../Card/utils';
import { useProjectDetailsById } from '../../../../../../hooks/useProjectDetailsById';
import { ProjectDetails } from '../../../../../ProjectDetails/ProjectDetails';
import { useLanguage } from '../../../../../Translations/LanguageProvider';
import { Tr } from '../../../../../Translations/Tr';

interface SummaryDetailsProps {
    onClose: () => void;
    item: CartItem;
}

export const SummaryDetails: FC<SummaryDetailsProps> = ({ onClose, item }) => {
    const { t } = useLanguage();
    const [wardrobeParams, setWardrobeParams] = useState<WardrobeNodeParams | null>(null);
    // const [wardrobePrice, setWardrobePrice] = useState<number>(0);
    const [wardrobeImg, setWardrobeImg] = useState<string | undefined>(undefined);
    const projectId = getProjectIdFromProject(item);
    const { grouped, wardrobeNode, project } = useProjectDetailsById(projectId);

    useEffect(() => {
        if (wardrobeNode) {
            setWardrobeParams(wardrobeNode.getParams());
        }
        if (project) {
            // setWardrobePrice(project.mainItem.unitPrice);
            setWardrobeImg(getProjectImageByType(project.mainItem.images, 'vertical-thumb'));
        }
    }, [project, wardrobeNode]);

    const { currentLanguage } = useLanguage();

    return (
        <ScrollableContentInSidePanel
            dataTestId="closet_details"
            title={t('Closet details')}
            sticky={
                <>
                    <Button
                        variant="primary"
                        onClick={onClose}
                        className="ml-auto"
                        dataTestId="footer-sidebar-back-button"
                        full
                    >
                        <Tr labelKey="close_summary_detail_button" defaultValue="Back" />
                    </Button>
                </>
            }
        >
            {wardrobeParams && wardrobeImg && (
                <div className="mb-20">
                    <CardHorizontal
                        dataTestId={wardrobeParams.product.code}
                        subtitle={wardrobeParams.product.name[currentLanguage] || ''}
                        title={formatMeasurements(wardrobeParams, t)}
                        imgPath={wardrobeImg}
                    />
                </div>
            )}

            <ProjectDetails grouped={grouped} />
        </ScrollableContentInSidePanel>
    );
};
