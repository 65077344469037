import { ColumnElement } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { AllColumnConfigurations } from '../../../../store/configurations/reducer';
import { convertValueFromCmToMm } from '../../../../store/products/utils';
import { WardrobeColumn } from '../../../../store/wardrobe/reducer';
import { ColumnConfigurationDetails, ConfigurationDynamicProduct } from '../../../../types/Configuration';
import { ShelveCode } from '../../../products/domain/ProductCodes';
import { ColumnNode, ColumnNodeParams } from '../nodes/ColumnNode';
import { DoubleDrawerNode } from '../nodes/DoubleDrawerNode';
import { DrawerNode } from '../nodes/DrawerNode';
import { GlassShelveNode } from '../nodes/GlassShelveNode';
import { HangerFrontNode } from '../nodes/HangerFrontNode';
import { HangerNode } from '../nodes/HangerNode';
import { LedNode } from '../nodes/LedNode';
import { ShelveNode } from '../nodes/ShelveNode';
import { SpaceNode } from '../nodes/SpaceNode';
import { AllProducts, chooseDepthVariant } from '../wardrobe';

interface DynamicElementsGenerationParam {
    dynamicBox?: ConfigurationDynamicProduct;
    emptySpace: number;
    regularDynamicElements: number;
    numberVerticallyStretchableElements: number;
    shelveType?: ShelveCode;
}

export class ColumnNodeBuilder {
    products: AllProducts;
    allConfigurations: AllColumnConfigurations;

    constructor(products: AllProducts, allConfigurations: AllColumnConfigurations) {
        this.allConfigurations = allConfigurations;
        this.products = products;
    }

    buildColumnByParamWithCustomLayout(
        column: Omit<ColumnNodeParams, 'product'>,
        elements: ColumnElement[],
        led: boolean,
    ) {
        const columnNode = ColumnNode.createColumnNode(
            [this.products.double_closet, this.products.single_closet],
            column,
        );

        elements.forEach((element) => {
            if (element.type === 'space') {
                columnNode.addChild(
                    new SpaceNode({
                        product: this.products.spacing,
                        box_height: convertValueFromCmToMm(element.heightBox),
                    }),
                );
            } else if (element.type === 'shelve') {
                columnNode.addChild(
                    new ShelveNode({
                        product: this.products.shelve,
                        material: column.material,
                        box_height: convertValueFromCmToMm(element.heightBox),
                    }),
                );
            } else if (element.type === 'glass_shelve') {
                columnNode.addChild(
                    new GlassShelveNode({
                        product: this.products.glass_shelve,
                        box_height: convertValueFromCmToMm(element.heightBox),
                        width: column.width,
                        depth: column.depth,
                    }),
                );
            } else if (element.type === 'drawer_box_double') {
                columnNode.addChild(
                    new DrawerNode({
                        product: this.products.double_drawer,
                        box_height: convertValueFromCmToMm(element.heightBox),
                        material: column.material,
                    }),
                );
            } else if (element.type === 'drawer_box_quadruple') {
                columnNode.addChild(
                    new DoubleDrawerNode({
                        product: this.products.double_drawer,
                        box_height: convertValueFromCmToMm(element.heightBox),
                        material: column.material,
                    }),
                );
            } else if (element.type === 'hanging_rod') {
                columnNode.addChild(
                    new HangerNode({
                        product: this.products.hanger,
                        box_height: convertValueFromCmToMm(element.heightBox),
                    }),
                );
            } else if (element.type === 'hanging_rod_front') {
                columnNode.addChild(
                    new HangerFrontNode({
                        product: this.products.hanger_front,
                        box_height: convertValueFromCmToMm(element.heightBox),
                        numberOfElement: 2,
                    }),
                );
            }
        });

        this.replaceGlassShelveToWoodenWhenNextNodeIsFrontHanger(columnNode);
        this.addLedToColumn(columnNode, led, column.height);

        return columnNode;
    }

    buildColumnByParam(
        column: Omit<ColumnNodeParams, 'product'>,
        configuration: ColumnConfigurationDetails,
        led: boolean,
    ) {
        const columnNode = ColumnNode.createColumnNode(
            [this.products.double_closet, this.products.single_closet],
            column,
        );

        this.createFixedElement(columnNode, configuration);
        this.replaceGlassShelveToWoodenWhenNextNodeIsFrontHanger(columnNode);
        this.addLedToColumn(columnNode, led, column.height);

        return columnNode;
    }

    buildColumn(column: WardrobeColumn, led: boolean) {
        const configId = column.configurationId;
        const depth = column.data.depth;

        if (configId === null) {
            return this.buildColumnByParamWithCustomLayout(
                {
                    depth: column.data.depth,
                    width: column.data.width,
                    height: column.data.height,
                    material: column.color,
                    doorProduct: column.door,
                    shelveType: column.shelveType || 'shelve',
                    doorHandleType: column.doorHandleType || null,
                    position: column.doorHandlePosition,
                    wall: column.wall || 'A',
                    layout: null,
                },
                column.elements || [],
                led,
            );
        }

        const configuration = chooseDepthVariant(this.allConfigurations[configId], depth);

        if (configuration === null) {
            throw new Error(`Configuration '${configId}' not exist`);
        }

        return this.buildColumnByParam(
            {
                depth: column.data.depth,
                width: column.data.width,
                height: column.data.height,
                material: column.color,
                doorProduct: column.door,
                shelveType: column.shelveType || 'shelve',
                doorHandleType: column.doorHandleType || null,
                position: column.doorHandlePosition,
                layout: `/api/v2/shop/layouts/${configId}`,
                wall: column.wall || 'A',
            },
            configuration,
            led,
        );
    }

    addLedToColumn(columnNode: ColumnNode, led: boolean, height: number) {
        if (led) {
            columnNode.addChild(new LedNode({ height, product: this.products.led }));
            columnNode.addChild(new LedNode({ height, product: this.products.led }));
        }
    }

    private replaceGlassShelveToWoodenWhenNextNodeIsFrontHanger(columnNode: ColumnNode) {
        const { material } = columnNode.getParams();
        const hangerNodes = columnNode.findChildrenByInstance([HangerFrontNode]);

        hangerNodes.forEach((hangerNode) => {
            const parentNode = hangerNode.getParent();

            if (!parentNode) {
                return;
            }

            const parentChildren = parentNode.getChildren();

            const shelveToReplace = parentChildren.reduce<GlassShelveNode | null>((acc, value, index) => {
                if (value instanceof GlassShelveNode) {
                    const nextItem = parentChildren[index + 1];

                    if (nextItem === hangerNode) {
                        return value;
                    }
                }

                return acc;
            }, null);

            if (!shelveToReplace) {
                return;
            }

            const { box_height: height } = shelveToReplace.getParams();

            parentNode.replaceChildren(
                shelveToReplace,
                new ShelveNode({
                    product: this.products.shelve,
                    box_height: height,
                    material,
                }),
            );
        });
    }

    private createFixedElement(columnNode: ColumnNode, configuration: ColumnConfigurationDetails) {
        const { material, height, shelveType } = columnNode.getParams();
        const minimalHeight = 2100;
        const dynamicBox = configuration?.dynamicBox;
        const emptySpace = height - minimalHeight;
        let increment = 0;
        const minimumSpace = dynamicBox?.firstItemDifference || 1;

        if (dynamicBox) {
            const regularDynamicElements = Math.floor(emptySpace / dynamicBox.height);

            const numberVerticallyStretchableElements = configuration.fixedElements.reduce(
                (sum, element) => (element.isVerticalStretchable ? sum + 1 : sum),
                0,
            );

            const rest = emptySpace - dynamicBox.height * regularDynamicElements;

            const params = {
                dynamicBox,
                emptySpace,
                regularDynamicElements,
                numberVerticallyStretchableElements,
                shelveType,
            } as DynamicElementsGenerationParam;

            increment = numberVerticallyStretchableElements > 0 ? rest / numberVerticallyStretchableElements : 0;

            this.createDynamicElements(columnNode, params);
        }

        configuration.fixedElements.forEach((element) => {
            const heightBox =
                element.isVerticalStretchable && emptySpace < minimumSpace
                    ? element.height + increment
                    : element.height;

            if (element.product.code === 'glass_shelve' || element.product.code === 'shelve') {
                if (shelveType === 'glass_shelve') {
                    columnNode.addChild(
                        new GlassShelveNode({
                            product: this.products.glass_shelve,
                            box_height: heightBox,
                        }),
                    );
                } else {
                    columnNode.addChild(
                        new ShelveNode({
                            product: this.products.shelve,
                            box_height: heightBox,
                            material,
                        }),
                    );
                }
            }

            if (element.product.code === 'double_drawer') {
                columnNode.addChild(
                    new DrawerNode({
                        product: this.products.double_drawer,
                        box_height: heightBox,
                        material,
                    }),
                );
            }

            if (element.product.code === 'quad_drawer') {
                columnNode.addChild(
                    new DoubleDrawerNode({ product: this.products.quad_drawer, box_height: heightBox, material }),
                );
            }

            if (element.product.code === 'hanger') {
                columnNode.addChild(new HangerNode({ product: this.products.hanger, box_height: heightBox }));
            }

            if (element.product.code === 'hanger_front') {
                columnNode.addChild(
                    new HangerFrontNode({ product: this.products.hanger_front, box_height: heightBox }),
                );
            }
        });
    }

    private createDynamicElements(columnNode: ColumnNode, params: DynamicElementsGenerationParam) {
        const { material } = columnNode.getParams();
        const dynamicBox = params.dynamicBox;

        if (dynamicBox) {
            const rest = params.emptySpace - dynamicBox.height * params.regularDynamicElements;
            const firstItemDifference = dynamicBox?.firstItemDifference || 1;
            const restAfterAddingFirstItemDifference = rest - firstItemDifference;

            if (
                restAfterAddingFirstItemDifference < 0 &&
                (params.numberVerticallyStretchableElements === 0 || params.regularDynamicElements > 0)
            ) {
                columnNode.addChild(
                    new SpaceNode({
                        product: this.products.spacing,
                        box_height: rest,
                    }),
                );
            }

            if (restAfterAddingFirstItemDifference >= 0) {
                const height = firstItemDifference + restAfterAddingFirstItemDifference;

                if (params.shelveType === 'glass_shelve') {
                    columnNode.addChild(
                        new GlassShelveNode({
                            product: this.products.glass_shelve,
                            box_height: height,
                        }),
                    );
                } else {
                    columnNode.addChild(
                        new ShelveNode({
                            product: this.products.shelve,
                            box_height: height,
                            material,
                        }),
                    );
                }
            }

            for (let i = 0; i < params.regularDynamicElements; i++) {
                if (params.shelveType === 'glass_shelve') {
                    columnNode.addChild(
                        new GlassShelveNode({
                            product: this.products.glass_shelve,
                            box_height: dynamicBox.height,
                        }),
                    );
                } else {
                    columnNode.addChild(
                        new ShelveNode({
                            product: this.products.shelve,
                            box_height: dynamicBox.height,
                            material,
                        }),
                    );
                }
            }
        }
    }
}
