import { getChannelCode } from '../env/env';
import { FetchService } from '../fetch/fetch';

interface CurrencySettings {
    currencies: string[];
    defaultCurrency: string;
}

export class CurrencyService {
    private fetch: FetchService;
    private availableLanguages: CurrencySettings | null = null;

    constructor(fetch: FetchService) {
        this.fetch = fetch;
    }

    setCurrency(currencyCode: string): void {
        localStorage.setItem('currency', currencyCode);
    }

    getCurrentCurrency(): string | null {
        const saved = localStorage.getItem('currency');

        return saved;
    }

    async getCurrencySettings(): Promise<CurrencySettings> {
        if (this.availableLanguages) {
            return this.availableLanguages;
        }
        const response = await this.fetch.get(`/api/v2/shop/channels/${getChannelCode()}`);

        if (isCurrencySettingsRaw(response)) {
            const availableLanguages: CurrencySettings = {
                currencies: response.currencies.map((currency) => currency.code),
                defaultCurrency: response.baseCurrency.code,
            };

            this.availableLanguages = availableLanguages;

            return availableLanguages;
        }

        return {
            currencies: [] as string[],
            defaultCurrency: '',
        };
    }
}

interface CurrencySettingsRaw {
    currencies: {
        code: string;
    }[];
    baseCurrency: {
        code: string;
    };
}

function isCurrencySettingsRaw(value: any): value is CurrencySettingsRaw {
    return (
        typeof value === 'object' &&
        value !== null &&
        Array.isArray(value.currencies) &&
        value.currencies.every(isLanguage) &&
        isLanguage(value.baseCurrency)
    );
}

function isLanguage(value: any): value is { code: string } {
    return typeof value === 'object' && value !== null && typeof value.code === 'string';
}
