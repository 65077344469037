import { ColumnElement } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { DOORS_SELECTION } from '../../../../constants/products/DoorSelection.enum';
import { RootState } from '../../../../store';
import { WardrobeColumn } from '../../../../store/wardrobe/reducer';
import { DoorHandleCode } from '../../../products/domain/ProductCodes';
import { ChromeDoorHandleNode } from '../nodes/ChromeDoorHandleNode';
import { ColumnNode } from '../nodes/ColumnNode';
import { DoorNode } from '../nodes/DoorNode';
import { DrawerNode } from '../nodes/DrawerNode';
import { GlassShelveNode } from '../nodes/GlassShelveNode';
import { GoldDoorHandleNode } from '../nodes/GoldDoorHandleNode';
import { HangerFrontNode } from '../nodes/HangerFrontNode';
import { HangerNode } from '../nodes/HangerNode';
import { LedNode } from '../nodes/LedNode';
import { ShelveNode } from '../nodes/ShelveNode';
import { WardrobeNode } from '../nodes/WardrobeNode';
import { WardrobeWallNode } from '../nodes/WardrobeWallNode';
import { WoodDoorHandleNode } from '../nodes/WoodDoorHandleNode';
import { WardrobeTreeVisitor } from './domain';
import { SpaceNode } from '../nodes/SpaceNode';
import { DoubleDrawerNode } from '../nodes/DoubleDrawerNode';
import { convertValueFromMmToCm } from '../../../../store/products/utils';
import { HangerFrontWithShelveNode } from '../nodes/HangerFrontWithShelveNode';

export class StoreVisitor
    implements
        WardrobeTreeVisitor<
            ColumnElement,
            Partial<RootState['wardrobe']>,
            ColumnElement,
            ColumnElement,
            ColumnElement,
            ColumnElement,
            ColumnElement,
            WardrobeColumn,
            null,
            ColumnElement,
            DoorHandleCode,
            DoorHandleCode,
            DoorHandleCode,
            null,
            null,
            null,
            ColumnElement[]
        >
{
    visitColumnNode(node: ColumnNode): WardrobeColumn {
        const { material, depth, height, width, wall } = node.getParams();
        const [doorNode] = node.findChildrenByInstance([DoorNode]);
        const glassShelves = node.findChildrenByInstance([GlassShelveNode]);

        const elements = node.findChildrenByInstance<
            | DrawerNode
            | ShelveNode
            | GlassShelveNode
            | HangerFrontNode
            | HangerNode
            | SpaceNode
            | DoubleDrawerNode
            | HangerFrontWithShelveNode
        >([
            DrawerNode,
            ShelveNode,
            GlassShelveNode,
            HangerFrontNode,
            HangerNode,
            SpaceNode,
            DoubleDrawerNode,
            HangerFrontWithShelveNode,
        ]);

        const configurationId = node.getConfigId();

        const [firstHandle] = node.findChildrenByInstance<
            ChromeDoorHandleNode | GoldDoorHandleNode | WoodDoorHandleNode
        >([ChromeDoorHandleNode, WoodDoorHandleNode, GoldDoorHandleNode]);

        const doorProduct = doorNode?.getParams().product || null;
        const elementsStore: ColumnElement[] = elements.flatMap((element) => element.visit(this));

        return {
            color: material,
            configurationId: configurationId || null,
            data: {
                depth,
                height,
                width,
            },
            door: doorProduct,
            doorSelection: doorProduct === null ? DOORS_SELECTION.NOT_SELECTED : DOORS_SELECTION.WITH_DOORS,
            hidden: false,
            doorHandleType: firstHandle ? firstHandle.visit(this) : null,
            doorHandlePosition: doorNode?.getParams().position || 'RIGHT',
            shelveType: glassShelves.length > 0 ? 'glass_shelve' : 'shelve',
            wall: wall || 'A',
            elements: elementsStore,
        };
    }

    visitWardrobeNode(node: WardrobeNode): Partial<RootState['wardrobe']> {
        const columnsNode = node.findChildrenByInstance([ColumnNode]);
        const ledNodes = node.findChildrenByInstance([LedNode]);
        const [firstColumnsNode] = columnsNode;
        const columns = columnsNode.map((column: ColumnNode) => column.visit(this));
        const allColumnsHaveDoor = columnsNode.every((node) => node.findChildrenByInstance([DoorNode]).length > 0);
        const doorProduct = firstColumnsNode ? firstColumnsNode.getParams().doorProduct : null;

        return {
            columns: columns,
            doors: allColumnsHaveDoor ? doorProduct : null,
            led: ledNodes.length !== 0,
        };
    }

    visitWrapperNode(): null {
        return null;
    }

    visitShelveNode(node: ShelveNode): ColumnElement {
        const { box_height } = node.getParams();

        return {
            type: 'shelve',
            heightBox: convertValueFromMmToCm(box_height || 0),
        };
    }
    visitHangerFrontNode(node: HangerFrontNode): ColumnElement {
        const { box_height } = node.getParams();

        return {
            type: 'hanging_rod_front',
            heightBox: convertValueFromMmToCm(box_height || 0),
            numberOfElements: node.getNumberOfHanger(),
        };
    }
    visitHangerNode(node: HangerNode): ColumnElement {
        const { box_height } = node.getParams();

        return {
            type: 'hanging_rod',
            heightBox: convertValueFromMmToCm(box_height || 0),
        };
    }
    visitGlassShelveNode(node: GlassShelveNode): ColumnElement {
        const { box_height } = node.getParams();

        return {
            type: 'glass_shelve',
            heightBox: convertValueFromMmToCm(box_height || 0),
        };
    }
    visitDrawerNode(node: DrawerNode): ColumnElement {
        const { box_height } = node.getParams();

        return {
            type: 'drawer_box_double',
            heightBox: convertValueFromMmToCm(box_height || 0),
        };
    }
    visitDoubleDrawerNode(node: DoubleDrawerNode): ColumnElement {
        const { box_height } = node.getParams();

        return {
            type: 'drawer_box_quadruple',
            heightBox: convertValueFromMmToCm(box_height || 0),
        };
    }
    visitDoorNode(): null {
        return null;
    }
    visitSpaceNode(node: SpaceNode): ColumnElement {
        const { box_height } = node.getParams();

        return {
            type: 'space',
            heightBox: convertValueFromMmToCm(box_height || 0),
        };
    }

    visitWoodDoorHandleNode(): DoorHandleCode {
        return 'door_handle_wood';
    }

    visitChromeDoorHandleNode(): DoorHandleCode {
        return 'door_handle_chrome';
    }

    visitGoldDoorHandleNode(): DoorHandleCode {
        return 'door_handle_gold';
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitLedNode(node: LedNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitWardrobeWallNode(node: WardrobeWallNode): null {
        return null;
    }

    visitHangerFrontWithShelve(node: HangerFrontWithShelveNode): ColumnElement[] {
        return [node.hanger.visit(this), node.shelve.visit(this)];
    }
}
