import { createReducer } from '@reduxjs/toolkit';
import { setAllProducts } from './actions';
import { Product } from '../../types/Product';

interface ProductsState {
    allProducts: Product[];
}

const initialState: ProductsState = {
    allProducts: [],
};

export default createReducer(initialState, (builder) => {
    builder.addCase(setAllProducts, (state, action) => {
        state.allProducts = action.payload;
    });
});
