import { WardrobePriceRequestParam, CustomOptionsValue } from '../../../../types/Project';
import { ColumnNode } from '../nodes/ColumnNode';
import { DoorNode } from '../nodes/DoorNode';
import { DoubleDrawerNode } from '../nodes/DoubleDrawerNode';
import { DrawerNode } from '../nodes/DrawerNode';
import { GlassShelveNode } from '../nodes/GlassShelveNode';
import { HangerFrontNode } from '../nodes/HangerFrontNode';
import { HangerNode } from '../nodes/HangerNode';
import { ShelveNode } from '../nodes/ShelveNode';
import { WoodDoorHandleNode } from '../nodes/WoodDoorHandleNode';
import { ChromeDoorHandleNode } from '../nodes/ChromeDoorHandleNode';
import { GoldDoorHandleNode } from '../nodes/GoldDoorHandleNode';
import { WardrobeNode } from '../nodes/WardrobeNode';
import { SomeNodeWithProduct, WardrobeTreeVisitor } from './domain';
import { LedNode } from '../nodes/LedNode';
import { WrapperNode } from '../nodes/WrapperNode';
import { WardrobeWallNode } from '../nodes/WardrobeWallNode';
import { SpaceNode } from '../nodes/SpaceNode';
import { HangerFrontWithShelveNode } from '../nodes/HangerFrontWithShelveNode';

export class ProjectVisitor implements WardrobeTreeVisitor<WardrobePriceRequestParam | WardrobePriceRequestParam[]> {
    visitShelveNode(node: ShelveNode): WardrobePriceRequestParam {
        return this.visitNode(node);
    }

    visitWardrobeNode(node: WardrobeNode): WardrobePriceRequestParam {
        return this.visitNode(node);
    }

    visitWrapperNode(node: WrapperNode): WardrobePriceRequestParam {
        return this.visitNode(node);
    }

    visitWoodDoorHandleNode(node: WoodDoorHandleNode): WardrobePriceRequestParam {
        return this.visitNode(node);
    }

    visitChromeDoorHandleNode(node: ChromeDoorHandleNode): WardrobePriceRequestParam {
        return this.visitNode(node);
    }

    visitGoldDoorHandleNode(node: GoldDoorHandleNode): WardrobePriceRequestParam {
        return this.visitNode(node);
    }

    visitWardrobeWallNode(node: WardrobeWallNode): WardrobePriceRequestParam {
        return this.visitNode(node);
    }

    visitHangerFrontNode(node: HangerFrontNode): WardrobePriceRequestParam[] {
        const numberOfHanger = node.getNumberOfHanger();

        return [...new Array(numberOfHanger).fill(this.visitNode(node))];
    }

    visitHangerFrontWithShelve(node: HangerFrontWithShelveNode): WardrobePriceRequestParam[] {
        return [node.shelve.visit(this), ...node.hanger.visit(this)];
    }

    visitSpaceNode(node: SpaceNode): WardrobePriceRequestParam {
        return this.visitNode(node);
    }

    visitLedNode(node: LedNode): WardrobePriceRequestParam {
        return this.visitNode(node);
    }

    visitHangerNode(node: HangerNode): WardrobePriceRequestParam {
        return this.visitNode(node);
    }

    visitGlassShelveNode(node: GlassShelveNode): WardrobePriceRequestParam {
        return this.visitNode(node);
    }

    visitDrawerNode(node: DrawerNode): WardrobePriceRequestParam {
        return this.visitNode(node);
    }

    visitDoubleDrawerNode(node: DoubleDrawerNode): WardrobePriceRequestParam {
        return this.visitNode(node);
    }

    visitColumnNode(node: ColumnNode): WardrobePriceRequestParam {
        const { layout } = node.getParams();

        if (layout === null) {
            return this.visitNode(node);
        }

        return {
            ...this.visitNode(node),
            layout,
        };
    }

    visitDoorNode(node: DoorNode): WardrobePriceRequestParam {
        return this.visitNode(node);
    }

    visitNode<T extends SomeNodeWithProduct>(node: T): WardrobePriceRequestParam {
        return {
            threeDParametersValues: this.getCustomOptionsValues(node),
            product: node.getProduct().path,
            childItems: node.getChildren().reduce<WardrobePriceRequestParam[]>((acc, node) => {
                const param: WardrobePriceRequestParam | WardrobePriceRequestParam[] = node.visit(this);

                if (Array.isArray(param)) {
                    acc.push(...param);
                } else {
                    acc.push(param);
                }

                return acc;
            }, []),
        };
    }

    getCustomOptionsValues<T extends SomeNodeWithProduct>(node: T): CustomOptionsValue[] {
        const entries = Object.entries(node.getParams());

        return node.getProduct().threeDParameters.reduce((updatedOptions: CustomOptionsValue[], option) => {
            const entry = entries.find(([key]) => option.genericCode === key.toUpperCase());
            const value = entry?.[1];

            if (value) {
                if (option.type === 'INT') {
                    updatedOptions.push({
                        threeDParameter: option['@id'],
                        value: Math.round(value).toString(),
                    });
                } else {
                    updatedOptions.push({
                        threeDParameter: option['@id'],
                        value: value.toString(),
                    });
                }
            } else {
                // eslint-disable-next-line no-console
                console.warn(`Could not find a value for option ${option.name} in entry ${node.constructor.name}`);
            }

            return updatedOptions;
        }, []);
    }
}
