import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Channel, ChannelService } from '../../services/channel/channel';
import { getChannelCode } from '../../services/env/env';
import { Loader } from '../atoms/Loader/Loader';

const DEFAULT_VALUE: Channel = {
    logo: null,
    name: '',
    favicon: null,
};

export const ChannelProvider = ({ children }: { children: React.ReactNode }) => {
    const channelService = useMemo(() => new ChannelService(), []);
    const [channel, setChannel] = useState<Channel | null>(null);

    useEffect(() => {
        if (!channelService) {
            return;
        }

        channelService.fetchChannel(getChannelCode()).then((channel) => {
            setChannel(channel);
        });
    }, [channelService]);

    if (!channel) {
        return <Loader isPending />;
    }

    return <ChannelContext.Provider value={channel}>{children}</ChannelContext.Provider>;
};

const ChannelContext = createContext<Channel>(DEFAULT_VALUE);

export const useChannel = () => useContext(ChannelContext);
