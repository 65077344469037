import { Product } from '../../../../types/Product';
import { WardrobeTreeVisitor } from '../visitor/domain';
import { ColumnNode } from './ColumnNode';
import { NodeWithProduct } from './NodeWithProduct';

interface WardrobeWallNodeParams {
    product: Product;
}

interface WardrobeWallNodeCalculatedParams {
    product: Product;
    width: number;
    height: number;
    depth: number;
}

export class WardrobeWallNode extends NodeWithProduct<WardrobeWallNodeParams> {
    getParams(): WardrobeWallNodeCalculatedParams {
        const params = super.getParams();
        const columns = this.findChildrenByInstance([ColumnNode]);
        const width = columns.reduce<number>((acc, column) => acc + column.getParams().width, 0);
        const height = Math.max(...columns.map<number>((column) => column.getParams().height));
        const depth = Math.max(...columns.map<number>((column) => column.getParams().depth));

        return {
            ...params,
            width,
            height,
            depth,
        };
    }
    visit<T extends WardrobeTreeVisitor<any>>(visitor: T): ReturnType<T['visitWardrobeWallNode']> {
        return visitor.visitWardrobeWallNode(this);
    }
}
