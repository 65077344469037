export default function imageLoader({ src, width, quality }) {
    if(src.indexOf('media/image/') !== -1){

        if(process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === 'local'){
            return `https://formifydev.com/${src}?width=${width}`
        }
        return `/${src}?width=${width}`
    }

    // static files in public folder
    return src;
}
