import React, { FC, useState } from 'react';
import { ButtonWrapperStyled, TextInputWithButtonWrapper } from './TextInputWithButton.styled';
import { SubtitleS } from '@formify/frontend-components';
import { TextInput } from '../atoms/TextInput/TextInput';

export interface TextInputWithButtonProps {
    onChange?: (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onClear?: () => void;
    buttonTitle?: string;
    label?: string;
    value?: string;
    responsiveWidth?: boolean;
    error?: boolean;
    success?: boolean;
    blocked?: boolean;
    loading?: boolean;
    name: string;
    isLoading?: boolean;
}

export const TextInputWithButton: FC<TextInputWithButtonProps> = ({
    label,
    value,
    buttonTitle,
    onChange,
    responsiveWidth,
    error,
    success,
    blocked,
    isLoading,
    onClear,
    name,
}) => {
    const [isInputHighlighted, setInputHighlighted] = useState(false);

    return (
        <TextInputWithButtonWrapper isLoading={isLoading} isInputHighlighted={isInputHighlighted}>
            <TextInput
                error={error}
                success={success}
                responsiveWidth={responsiveWidth}
                value={value}
                onChange={onChange}
                label={label}
                blocked={blocked}
                isLoading={isLoading}
                onClear={onClear}
                name={name}
            >
                {buttonTitle && !isLoading && (
                    <ButtonWrapperStyled
                        onMouseEnter={() => setInputHighlighted(true)}
                        onMouseLeave={() => setInputHighlighted(false)}
                    >
                        <SubtitleS fontWeight={400}>{buttonTitle}</SubtitleS>
                    </ButtonWrapperStyled>
                )}
            </TextInput>
        </TextInputWithButtonWrapper>
    );
};
