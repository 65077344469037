import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from '../index';
import { CartData } from '../../types/Cart';
import { BlockItem, CountryResponse, SaveProjectResponse } from '../../types/Project';
import { UserAddressForm, UserAddressRequestData } from '../../types/Checkout';
import { createWardrobeStructureBasedOnState } from '../../services/nodes/wardrobe/wardrobe';
import { ProjectVisitor } from '../../services/nodes/wardrobe/visitor/ProjectVisitor';
import { RendererVisitor } from '../../services/nodes/wardrobe/visitor/RendererVisitor';
import { saveImage } from '../../services/images/images';
import { GetScreenFunction } from '../../components/TakeScreenshot/takeScreenContext';
import { createAsyncThunkWithShowError } from '../utils/createAsyncThunkWithShowError';
import { getServices } from '../dependency/selectors';
import { pushEvent } from '../../services/analytics/analytics';
import { convertValueFromCentsToEuro } from '../configurations/utils';
import { WardrobeContext } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { uploadFile } from '../../services/file3D/file3D';
import { CreateScreenParams } from '@formify/frontend-wardrobe-renderer/dist/src/context/actions';

export const fetchOrderData = createAsyncThunkWithShowError<CartData | null, void, ThunkApiConfig>(
    'project/fetchOrderData',
    async (_, { getState }) => {
        const state = getState();
        const { cart } = getServices(state);

        return await cart.getCartData();
    },
);

export const removeCartItem = createAsyncThunkWithShowError<void, number, ThunkApiConfig>(
    'project/removeCartItem',
    async (id, { dispatch, getState }) => {
        const state = getState();
        const { cart } = getServices(state);

        await cart.deleteCartItem(id);
        await dispatch(fetchOrderData());
    },
);

export const changeCartItemQuantity = createAsyncThunkWithShowError<
    CartData | null,
    { cartItemId: number; quantity: number },
    ThunkApiConfig
>('project/changeCartItemQuantity', async ({ cartItemId, quantity }, { dispatch, getState }) => {
    const state = getState();
    const { cart } = getServices(state);

    return cart.changeCartItemQuantity(cartItemId, quantity);
});

export const addDiscountCodeToOrder = createAsyncThunk<void, string, ThunkApiConfig>(
    'project/addDiscountCodeToOrder',
    async (value, { dispatch, getState }) => {
        const state = getState();
        const { cart } = getServices(state);

        await cart.applyCoupon(value);
        await dispatch(fetchOrderData());
    },
);

export const removeDiscountCode = createAsyncThunkWithShowError<void, void, ThunkApiConfig>(
    'project/removeDiscountCode',
    async (_, { dispatch, getState }) => {
        const state = getState();
        const { cart } = getServices(state);

        await cart.deleteDiscountCode();
        await dispatch(fetchOrderData());
    },
);

type SaveProjectParams = {
    purpose?: string;
    email?: string;
    cartIri?: string;
    getScreenshot: GetScreenFunction;
    get3DFile?: WardrobeContext['get3DFile'];
    threeDimensionFileType?: 'glb' | 'usdz-and-glb-for-ar';
    currencyCode: string;
};

const THUMB_PROJECT_WITHOUT_DOORS: CreateScreenParams = {
    width: 1336,
    height: 964,
    doors: false,
    innerElements: false,
    samples: 4,
};

const THUMB_PROJECT_WITH_DOORS: CreateScreenParams = {
    width: 334,
    height: 241,
    doors: true,
    innerElements: false,
    samples: 4,
};

const VERTICAL_THUMB_PROJECT: CreateScreenParams = {
    width: 140,
    height: 166,
    doors: true,
    innerElements: false,
    samples: 8,
};

export const renderer3DVisitor = new RendererVisitor();

export const saveCurrentWardrobeAsProject = createAsyncThunkWithShowError<
    SaveProjectResponse,
    SaveProjectParams,
    ThunkApiConfig
>(
    'project/saveCurrentWardrobeAsProject',
    async (
        { email, cartIri, getScreenshot, get3DFile, purpose, threeDimensionFileType = 'glb', currencyCode },
        { getState },
    ) => {
        const state = getState();
        const { project, performance } = getServices(state);
        const currentWardrobe = createWardrobeStructureBasedOnState(state);

        if (currentWardrobe === null) {
            throw new Error('currentWardrobe is not created');
        }

        const visitorProject = new ProjectVisitor();

        const stopTrace = await performance?.trace(
            get3DFile ? 'prepare_files_for_project' : 'prepare_images_for_project',
        );

        const exportAsAR = threeDimensionFileType === 'usdz-and-glb-for-ar';

        const files3DIri = Promise.all([
            get3DFile ? get3DFile('glb', exportAsAR ? 4096 : 512).then((file) => uploadFile(file, 'glb')) : null,
            get3DFile && exportAsAR ? get3DFile('usdz', 4096).then((file) => uploadFile(file, 'usdz')) : null,
        ]);

        const [file3DIri, ...imagesIri] = await Promise.all([
            files3DIri,
            getScreenshot(THUMB_PROJECT_WITH_DOORS).then((blob) => saveImage(blob, 'with-door')),
            getScreenshot(THUMB_PROJECT_WITHOUT_DOORS).then((blob) => saveImage(blob, 'without-door')),
            getScreenshot(VERTICAL_THUMB_PROJECT).then((blob) => saveImage(blob, 'vertical-thumb')),
        ]);

        const mainItem = visitorProject.visitWardrobeNode(currentWardrobe);

        stopTrace && stopTrace();

        const response = await project.saveProject({
            email,
            purpose,
            cart: cartIri,
            imagesIri,
            mainItem,
            projectItemFilesIri: file3DIri.reduce<string[]>((acc, curr) => {
                if (curr) {
                    acc.push(curr);
                }

                return acc;
            }, []),
            currencyCode,
        });

        if (cartIri) {
            pushEvent('add_to_cart', {
                currency: currencyCode,
                value: convertValueFromCentsToEuro(response.mainItem.unitPriceWithChildItems),
                items: [
                    {
                        item_id: response.id,
                        item_name: mainItem.product.replace('/api/v2/shop/products/', ''),
                        item_category: 'wardrobe',
                        price: convertValueFromCentsToEuro(response.mainItem.unitPriceWithChildItems),
                        quantity: 1,
                    },
                ],
            });
        }

        return response;
    },
);

export const updateUserAddress = createAsyncThunkWithShowError<void, UserAddressForm, ThunkApiConfig>(
    'checkout/updateUserAddressData',
    async (
        {
            email,
            firstName,
            lastName,
            firstNameBilling,
            lastNameBilling,
            country,
            street,
            postCode,
            city,
            countryBilling,
            streetBilling,
            postCodeBilling,
            cityBilling,
            info,
            infoBilling,
            subscribedToNewsletter,
            areSameAddresses,
            floor,
            hasElevator,
            phoneNumber,
            phonePrefix,
        },
        { dispatch, getState },
    ) => {
        const formattedUserData: UserAddressRequestData = {
            email: email,
            subscribedToNewsletter,
            shippingAddress: {
                firstName: firstName,
                lastName: lastName,
                countryCode: country,
                street: street,
                postcode: postCode,
                city: city,
                notes: info,
                floor,
                hasElevator,
                phoneNumber,
                phonePrefix,
            },
            billingAddress: {
                firstName: areSameAddresses ? firstName : firstNameBilling,
                lastName: areSameAddresses ? lastName : lastNameBilling,
                countryCode: areSameAddresses ? country : countryBilling,
                street: areSameAddresses ? street : streetBilling,
                postcode: areSameAddresses ? postCode : postCodeBilling,
                city: areSameAddresses ? city : cityBilling,
                notes: infoBilling,
                phoneNumber,
                phonePrefix,
            },
        };

        const state = getState();
        const { cart } = getServices(state);

        await cart.editUserAddress(formattedUserData);
        await dispatch(fetchOrderData());
    },
);

export const setBlockItems = createAction<BlockItem[]>('project/setBlockItems');

export const setOrderData = createAction<CartData | null>('project/setOrderData');

export const setAvailableCountries = createAction<CountryResponse[]>('project/setAvailableCountries');

export const setAllCountries = createAction<CountryResponse[]>('project/setAllCountries');

export const setLoadingAddingProjectToCartStatus = createAction<boolean>('project/setLoadingAddingProjectToCartStatus');

export const clearDiscountCodeData = createAction<void>('project/clearDiscountCodeData');

export const setCountryForSavingProject = createAction<string>('project/setCountryForSavingProject');

export const showLoaderOnCheckoutAction = createAction<boolean>('project/showLoaderOnCheckout');

export const setUpdateOrderByAdmin = createAction<{
    token: string;
    orderItemId: string;
}>('project/setUpdateOrderByAdmin');
