import { DoorHandlePositionCode } from '../../../products/domain/ThreeDParameter';
import { DoorHandleCode } from '../../../products/domain/ProductCodes';
import { ChromeDoorHandleNode } from '../nodes/ChromeDoorHandleNode';
import { ColumnNode } from '../nodes/ColumnNode';
import { DoorNode, DoorNodeParams } from '../nodes/DoorNode';
import { GoldDoorHandleNode } from '../nodes/GoldDoorHandleNode';
import { WoodDoorHandleNode } from '../nodes/WoodDoorHandleNode';
import { AllProducts } from '../wardrobe';

export class DoorsBuilder {
    products: AllProducts;

    constructor(products: AllProducts) {
        this.products = products;
    }

    build(column: ColumnNode, handlePosition: DoorHandlePositionCode, glass: boolean, handle: DoorHandleCode | null) {
        const { width } = column.getParams();

        if (column.isDouble()) {
            return this.buildDoubleDoors(column, handlePosition, glass, handle);
        }

        return [this.buildSingleDoors(column, handlePosition, glass, handle, width)];
    }

    buildSingleDoors(
        column: ColumnNode,
        handlePosition: DoorHandlePositionCode,
        glass: boolean,
        handle: DoorHandleCode | null,
        width: number,
    ) {
        const { height, material } = column.getParams();

        return this.createDoor(
            {
                height,
                width,
                material,
                position: handlePosition,
            },
            glass,
            handle,
        );
    }

    buildDoubleDoors(
        column: ColumnNode,
        handlePosition: DoorHandlePositionCode | 'DOUBLE',
        glass: boolean,
        handle: DoorHandleCode | null = null,
    ) {
        const { width } = column.getParams();

        return [
            this.buildSingleDoors(
                column,
                'LEFT',
                glass,
                handlePosition === 'LEFT' || handlePosition === 'DOUBLE' ? handle : null,
                width / 2,
            ),
            this.buildSingleDoors(
                column,
                'RIGHT',
                glass,
                handlePosition === 'RIGHT' || handlePosition === 'DOUBLE' ? handle : null,
                width / 2,
            ),
        ];
    }

    private createDoor(params: Omit<DoorNodeParams, 'product'>, glass: boolean, handle: DoorHandleCode | null = null) {
        const doorNode = new DoorNode({
            ...params,
            product: glass ? this.products.door_glass : this.products.door,
        });

        if (handle) {
            doorNode.addChild(this.createHandle(handle));
        }

        return doorNode;
    }

    private createHandle(
        doorHandleType?: DoorHandleCode,
    ): ChromeDoorHandleNode | GoldDoorHandleNode | WoodDoorHandleNode {
        if (doorHandleType === 'door_handle_wood') {
            return new WoodDoorHandleNode({ product: this.products['door_handle_wood'] });
        }
        if (doorHandleType === 'door_handle_chrome') {
            return new ChromeDoorHandleNode({ product: this.products['door_handle_chrome'] });
        }

        return new GoldDoorHandleNode({ product: this.products['door_handle_gold'] });
    }
}
