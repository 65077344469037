import { Column, ColumnElement, DoorElement } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { INITIAL_COLUMN_VALUES } from '../../../../constants/products/Wardrobe';
import { ChromeDoorHandleNode } from '../nodes/ChromeDoorHandleNode';
import { GoldDoorHandleNode } from '../nodes/GoldDoorHandleNode';
import { ColumnNode } from '../nodes/ColumnNode';
import { DoorNode } from '../nodes/DoorNode';
import { DoubleDrawerNode } from '../nodes/DoubleDrawerNode';
import { DrawerNode } from '../nodes/DrawerNode';
import { GlassShelveNode } from '../nodes/GlassShelveNode';
import { HangerFrontNode } from '../nodes/HangerFrontNode';
import { HangerNode } from '../nodes/HangerNode';
import { ShelveNode } from '../nodes/ShelveNode';
import { SpaceNode } from '../nodes/SpaceNode';
import { WardrobeNode } from '../nodes/WardrobeNode';
import { WoodDoorHandleNode } from '../nodes/WoodDoorHandleNode';
import { WardrobeTreeVisitor } from './domain';
import { WrapperNode } from '../nodes/WrapperNode';
import { convertValueFromMmToCm } from '../../../../store/products/utils';
import { HangerFrontWithShelveNode } from '../nodes/HangerFrontWithShelveNode';

export class RendererVisitor
    implements
        WardrobeTreeVisitor<
            ColumnElement[],
            Column[],
            ColumnElement[],
            ColumnElement[],
            ColumnElement[],
            ColumnElement[],
            ColumnElement[],
            Column,
            Column['doors'][number],
            ColumnElement[],
            DoorElement['handler'],
            DoorElement['handler'],
            DoorElement['handler'],
            null,
            Column[],
            null,
            ColumnElement[]
        >
{
    visitShelveNode(node: ShelveNode): ColumnElement[] {
        return [
            {
                type: 'shelve',
                heightBox: convertValueFromMmToCm(node.getParams().box_height),
            },
        ];
    }

    visitWardrobeNode(node: WardrobeNode): Column[] {
        const columns = node.findChildrenByInstance([ColumnNode]);

        return columns.map((column) => this.visitColumnNode(column));
    }

    visitWrapperNode(node: WrapperNode): Column[] {
        const columns = node.findChildrenByInstance([ColumnNode]);

        return columns.map((column) => this.visitColumnNode(column));
    }

    visitHangerFrontNode(node: HangerFrontNode): ColumnElement[] {
        const numberOfElements = node.getNumberOfHanger();

        return [
            {
                type: 'hanging_rod_front',
                numberOfElements,
                heightBox: convertValueFromMmToCm(node.getParams().box_height),
            },
        ];
    }

    visitHangerNode(node: HangerNode): ColumnElement[] {
        return [
            {
                type: 'hanging_rod',
                heightBox: convertValueFromMmToCm(node.getParams().box_height),
            },
        ];
    }

    visitGlassShelveNode(node: GlassShelveNode): ColumnElement[] {
        return [
            {
                type: 'glass_shelve',
                heightBox: convertValueFromMmToCm(node.getParams().box_height),
            },
        ];
    }

    visitDrawerNode(node: DrawerNode): ColumnElement[] {
        const heightBox = convertValueFromMmToCm(node.getParams().box_height);

        return [
            {
                type: 'drawer_box_double',
                heightBox: heightBox,
            },
        ];
    }

    visitSpaceNode(node: SpaceNode): ColumnElement[] {
        return [
            {
                type: 'space',
                heightBox: convertValueFromMmToCm(node.getParams().box_height),
            },
        ];
    }

    visitDoubleDrawerNode(node: DoubleDrawerNode): ColumnElement[] {
        const heightBox = convertValueFromMmToCm(node.getParams().box_height);

        return [
            {
                type: 'drawer_box_quadruple',
                heightBox: heightBox,
            },
        ];
    }

    visitColumnNode(node: ColumnNode): Column {
        const doorNodes = node.findChildrenByInstance([DoorNode]);

        const nodeElements = node.findChildrenByInstance<
            ShelveNode | HangerNode | HangerFrontNode | GlassShelveNode | DrawerNode | DoubleDrawerNode | SpaceNode
        >([ShelveNode, HangerNode, HangerFrontNode, GlassShelveNode, DrawerNode, DoubleDrawerNode, SpaceNode]);

        const elements = nodeElements.reduce<ColumnElement[]>((columnElements, element) => {
            columnElements.push(...element.visit(this));

            return columnElements;
        }, []);

        const { depth, height, width, wall } = node.getParams();

        return {
            doors: doorNodes.map((doorNode) => this.visitDoorNode(doorNode)),
            elements,
            depth: convertValueFromMmToCm(depth),
            height: convertValueFromMmToCm(height),
            width: convertValueFromMmToCm(width),
            legs: ColumnNode.getNumberOfLegs(width),
            wall: wall,
            thickness: INITIAL_COLUMN_VALUES.thickness,
        };
    }

    visitDoorNode(node: DoorNode): Column['doors'][number] {
        const [firstHandle] = node.findChildrenByInstance<
            ChromeDoorHandleNode | GoldDoorHandleNode | WoodDoorHandleNode
        >([ChromeDoorHandleNode, WoodDoorHandleNode, GoldDoorHandleNode]);

        const { width, position } = node.getParams();
        const doorType = position === 'LEFT' ? 'right' : 'left';
        const glass = node.getProduct().code === 'door_glass';
        const handler = firstHandle ? firstHandle.visit(this) : null;
        const numberOfHinge = node.getNumberOfHinge();

        return {
            handler: handler,
            glass: glass,
            type: doorType,
            numberOfHinge,
            doorWidth: convertValueFromMmToCm(width),
        };
    }

    visitWoodDoorHandleNode(): DoorElement['handler'] {
        return '2';
    }

    visitChromeDoorHandleNode(): DoorElement['handler'] {
        return '1';
    }

    visitGoldDoorHandleNode(): DoorElement['handler'] {
        return '5';
    }

    visitLedNode(): null {
        return null;
    }

    visitWardrobeWallNode(): null {
        return null;
    }

    visitHangerFrontWithShelve(node: HangerFrontWithShelveNode): ColumnElement[] {
        return [
            {
                type: 'hanging_rod_front_with_shelve',
                heightBox: convertValueFromMmToCm(node.hanger.getParams().box_height),
                numberOfElements: node.hanger.getNumberOfHanger(),
                shelve: true,
            },
        ];
    }
}
