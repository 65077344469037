import { ThreeDParameter } from '../../services/products/domain/ThreeDParameter';
import { Range } from '../../types/Product';
import {
    WARDROBE_FRAME_BOTTOM_HEIGHT,
    WARDROBE_FRAME_THICKNESS,
} from '@formify/frontend-wardrobe-renderer/dist/modules/constants';

export interface OptionDimensions {
    width: Range;
    height: Range;
    depth: Range;
}

export const getFormattedProductOptions = (options: ThreeDParameter[]) =>
    options.reduce<OptionDimensions>(
        (formattedOptions, option) => {
            if (option.type !== 'INT') {
                return formattedOptions;
            }
            const { intValidation } = option;

            if (option.genericCode === 'WIDTH') {
                formattedOptions.width = intValidation;
            }
            if (option.genericCode === 'HEIGHT') {
                formattedOptions.height = intValidation;
            }
            if (option.genericCode === 'DEPTH') {
                formattedOptions.depth = intValidation;
            }

            return formattedOptions;
        },
        {
            width: null,
            height: null,
            depth: null,
        },
    );

export interface WardrobeDimensions {
    singleClosetWidth: Range;
    doubleClosetWidth: Range;
    wardrobeWidth: Range;
    wardrobeHeight: Range;
    wardrobeDepth: Range;
}

export const convertValueFromMmToCm = (valueInMm: number) => valueInMm / 10;

export const convertValueFromCmToMm = (valueInCm: number) => valueInCm * 10;

export const WARDROBE_FRAME_THICKNESS_MM = convertValueFromCmToMm(WARDROBE_FRAME_THICKNESS);

export const WARDROBE_FRAME_BOTTOM_HEIGHT_MM = convertValueFromCmToMm(WARDROBE_FRAME_BOTTOM_HEIGHT);
