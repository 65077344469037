import { Product } from '../../../../types/Product';
import { WardrobeTreeVisitor } from '../visitor/domain';
import { NodeWithProduct } from './NodeWithProduct';

interface LedNodeParams {
    product: Product;
    height: number;
}

export class LedNode extends NodeWithProduct<LedNodeParams> {
    visit<T extends WardrobeTreeVisitor<any>>(visitor: T): ReturnType<T['visitLedNode']> {
        return visitor.visitLedNode(this);
    }
}
