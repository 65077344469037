import { Selector } from 'react-redux';
import { CartData, CartItem } from '../../types/Cart';
import type { RootState } from '../index';
import { CountryData, SplittedCountryData } from '../../types/Project';
import { ALL_COUNTRIES } from '../../constants/country';
import { UserAddressResponseData } from '../../types/Checkout';
import { convertValueFromCentsToEuro } from '../configurations/utils';

export const DEFAULT_LOCALE_CODE = 'en_US';

export const getUpdateOrderByAdminSettings = (state: RootState) => state.project.updateOrderByAdminSettings;

export const getIsAddingProject: Selector<RootState, boolean> = (state) => state.project.isAddingProject;

export const getOrderData: Selector<RootState, CartData | null> = (state) => state.project.orderData;

export const isStripePaymentAvailable = (state: RootState) =>
    (state.project.orderData?.userAddressData.payments || []).find((payment) => payment.method.name === 'Stripe') !==
    undefined;

export const getCartItems: Selector<RootState, CartItem[] | undefined> = (state) => state.project.orderData?.items;

export const getTotalCartPrice: Selector<RootState, number | undefined> = (state) => state.project.orderData?.total;

export const getDeliveryCost: Selector<RootState, number | undefined> = (state) =>
    state.project.orderData?.shippingTotal;

export const getBlockHTML =
    (code: string, locale = DEFAULT_LOCALE_CODE): Selector<RootState, string | undefined> =>
    (state) => {
        const item = state.project.blockItems.find((item) => item.code === code);

        if (item) {
            return item.translations[locale]?.content;
        }

        return undefined;
    };

export const getCartItemsCount: Selector<RootState, number> = (state) => {
    const wardrobeItems = state.project.orderData?.items;

    if (wardrobeItems) {
        return wardrobeItems.length;
    }

    return 0;
};

export const getAllRemovingCartIds: Selector<RootState, number[]> = (state) => state.project.allRemovingCartIds;

export const getFormattedCountries: Selector<RootState, SplittedCountryData> = (state) => {
    const { availableCountries, allCountries } = state.project;
    const available: CountryData[] = [];
    const unavailable: CountryData[] = [];

    ALL_COUNTRIES.forEach((item) => {
        if (allCountries.includes(item.value)) {
            if (availableCountries.includes(item.value)) {
                available.push(item);
            } else {
                unavailable.push(item);
            }
        }
    });

    return {
        available,
        unavailable,
    };
};

export const getIsLoadingDiscountCode: Selector<RootState, boolean> = (state) => state.project.isLoadingDiscountCode;

export const getDiscountCodeError: Selector<RootState, string | null> = (state) => state.project.discountCodeError;

export const getOrderPromotionCode: Selector<RootState, string | null> = (state) => {
    if (state.project.orderData?.promotionCoupon) {
        return state.project.orderData.promotionCoupon.code;
    }

    return null;
};
export const getStripeClientSecret = (state: RootState) => state.project.orderData?.payments?.[0]?.stripeClientSecret;

export const getOrderPromotionDiscount =
    (formatPrice: (price: string | number) => string) =>
    (state: RootState): string | null => {
        if (state.project.orderData) {
            const actions = formatOrderPromotionDiscount(state.project.orderData, formatPrice);

            if (actions.length > 0) {
                return actions.join(', ');
            }
        }

        return null;
    };

const formatOrderPromotionDiscount = (orderData: CartData, formatPrice: (price: string | number) => string) => {
    if (orderData.promotionCoupon) {
        const actions = orderData.promotionCoupon.promotion.actions.reduce<string[]>((acc, action) => {
            if (action.type === 'order_fixed_discount') {
                acc.push(`${formatPrice(convertValueFromCentsToEuro(action.configuration.amount))}`);
            } else if (action.type === 'order_percentage_discount') {
                acc.push(`${Math.round(action.configuration.percentage * 100)}%`);
            } else if (action.type === 'shipping_percentage_discount') {
                acc.push(`${Math.round(action.configuration.percentage * 100)}%`);
            } else if (action.type === 'unit_percentage_discount') {
                acc.push(`${Math.round(action.configuration.percentage * 100)}%`);
            } else if (action.type === 'unit_fixed_discount') {
                acc.push(`${formatPrice(convertValueFromCentsToEuro(action.configuration.amount))}`);
            }

            return acc;
        }, []);

        return actions;
    }

    return [];
};

export const getUserAddressData: Selector<RootState, UserAddressResponseData | undefined> = (state) =>
    state.project.orderData?.userAddressData;

export const getIsFetchingCartData: Selector<RootState, boolean> = (state) => state.project.isFetchingCartData;

export const getLoaderOnCheckoutVisibility: Selector<RootState, boolean> = (state) =>
    state.project.showLoaderOnCheckout;

export const getCountryForSavingProject: Selector<RootState, string | null> = (state) =>
    state.project.selectedCountryForSavingProject;
