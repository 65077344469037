export abstract class Node<T extends { [key: string]: any }> {
    private children: Node<any>[];
    private parent: Node<T> | null;
    protected params: T;

    constructor(params: T) {
        this.children = [];
        this.params = params;
        this.parent = null;
    }

    getParams(): T {
        return { ...this.params };
    }

    getParent(): Node<any> | null {
        return this.parent;
    }

    abstract visit(visitor: any): any;

    findParentByInstance<T extends Node<any>>(constructor: new (...params: any) => T): T | null {
        let node = this.getParent();

        while (node) {
            if (node instanceof constructor) {
                return node;
            }
            node = node.getParent();
        }

        return null;
    }

    findChildrenByInstance<T extends Node<any>>(constructors: (new (...params: any) => T)[]): T[] {
        const nodes = this.getChildren();

        return nodes.reduce<T[]>((acc, node) => {
            constructors.forEach((constructor) => {
                if (node instanceof constructor) {
                    acc.push(node);
                }
            });

            acc.push(...node.findChildrenByInstance(constructors));

            return acc;
        }, []);
    }

    getChildren(): Node<any>[] {
        return [...this.children];
    }

    removeChild(nodeToRemove: Node<any>): void {
        this.children = this.children.filter((node) => {
            if (node === nodeToRemove) {
                nodeToRemove.parent = null;

                return false;
            }

            return true;
        });
    }

    removeChildren(): void {
        this.children.forEach((node) => {
            node.parent = null;
        });
        this.children = [];
    }

    removeChildByInstance<T extends Node<any>>(constructor: new (...params: any) => T): void {
        const nodes = this.findChildrenByInstance<T>([constructor]);

        nodes.forEach((node) => {
            this.removeChild(node);
        });
    }

    addChild(child: Node<any>) {
        this.children.push(child);
        child.parent = this;
    }

    addChildren(children: Node<any>[]) {
        children.forEach((child) => {
            this.addChild(child);
        });
    }

    prependChildren(child: Node<any>) {
        this.children = [child, ...this.children];
        child.parent = this;
    }

    replaceChildren(currentChild: Node<any>, newChild: Node<any>) {
        const index = this.children.findIndex((node) => node === currentChild);

        if (index === -1) {
            throw new Error('currentChild not exists in children');
        }
        this.children[index] = newChild;
        currentChild.parent = null;
        newChild.parent = this;
    }
}
