import { useState, useCallback } from 'react';
import { useStore } from 'react-redux';
import { getProject } from '../services/project/project';
import { WardrobeBuilderFromProject } from '../services/nodes/wardrobe/builders/WardrobeBuilderFromProject';
import { WardrobeNode } from '../services/nodes/wardrobe/nodes/WardrobeNode';
import { GroupedVisitor, GroupResult } from '../services/nodes/wardrobe/visitor/GroupedVisitor';
import { getAllProducts } from '../services/nodes/wardrobe/wardrobe';
import { RootState } from '../store';
import { GetProjectResponse, ProjectResponseNode } from '../types/Project';
import { useLockEffect } from './useLockEffect';
import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { ColumnNode } from '../services/nodes/wardrobe/nodes/ColumnNode';
import { isWardrobeCode } from '../services/products/domain/ProductCodes';

interface GroupResultWithPrice extends GroupResult {
    price: number;
}

export interface GroupResultWithPriceWall {
    columns: GroupResultWithPrice[];
    wall: WardrobeWall;
    price: number;
    width: number;
    height: number;
    depth: number;
}

export const useProjectDetailsById = (projectId: string) => {
    const [project, setProject] = useState<GetProjectResponse | null>(null);
    const [wardrobeNode, setWardrobeNode] = useState<WardrobeNode | null>(null);
    const [grouped, setGrouped] = useState<GroupResultWithPriceWall[]>([]);
    const { getState } = useStore<RootState>();

    const findPriceByLayoutId = useCallback(
        (node: ProjectResponseNode, data: GroupResult): ProjectResponseNode | undefined => {
            const state = getState();
            const formattedProducts = getAllProducts(state);

            if (formattedProducts === null) {
                return undefined;
            }
            const builder = new WardrobeBuilderFromProject(formattedProducts);
            const params = data.column.getParams();

            const columnWithPrice = node.childItems.find((item) => {
                const node = builder.createNodeByProduct(item);

                if (node instanceof ColumnNode) {
                    const childParams = node.getParams();

                    return (
                        childParams.width === params.width &&
                        childParams.height === params.height &&
                        childParams.depth === params.depth &&
                        childParams.layout === params.layout
                    );
                }

                return false;
            });

            if (columnWithPrice) {
                return columnWithPrice;
            }

            const value = node.childItems.reduce<ProjectResponseNode | undefined>((acc, node) => {
                if (acc) {
                    return acc;
                }

                return findPriceByLayoutId(node, data);
            }, undefined);

            return value;
        },
        [getState],
    );

    const fetchProject = useCallback(async () => {
        const state = getState();
        const project = await getProject(projectId);

        if (!isWardrobeCode(project.mainItem.product.replace('/api/v2/shop/products/', ''))) {
            return [];
        }
        const formattedProducts = getAllProducts(state);

        if (!formattedProducts) {
            return [];
        }
        const builder = new WardrobeBuilderFromProject(formattedProducts);
        const wardrobeNode = builder.getWardrobe(project);

        if (wardrobeNode === null) {
            return [];
        }

        setWardrobeNode(wardrobeNode);
        setProject(project);

        const visitor = new GroupedVisitor();
        const groupedWall = visitor.visitWardrobeNode(wardrobeNode);

        return groupedWall.map((wallGroup): GroupResultWithPriceWall => {
            const columns = wallGroup.columns.map((column): GroupResultWithPrice => {
                const columnWithPrice = findPriceByLayoutId(project.mainItem, column);
                const price = columnWithPrice?.unitPriceWithChildItems;

                return { ...column, price: price || 0 };
            });

            return {
                columns,
                wall: wallGroup.wall,
                price: columns.reduce((sum, column) => sum + column.price * column.quantity, 0),
                width: columns.reduce((sum, column) => sum + column.details.width * column.quantity, 0),
                depth: Math.max(...columns.map((column) => column.details.depth)),
                height: Math.max(...columns.map((column) => column.details.height)),
            };
        });
    }, [findPriceByLayoutId, getState, projectId]);

    useLockEffect(fetchProject, setGrouped);

    return { grouped, wardrobeNode, project };
};
