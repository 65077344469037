import { Product } from '../../../../types/Product';
import { Node } from '../../common/nodes/Node';

export abstract class NodeWithProduct<T extends { product: Product; [key: string]: any }> extends Node<T> {
    getProduct() {
        return this.getParams().product;
    }

    setParam<Key extends keyof T>(key: Key, value: T[Key]) {
        if (this.validateParamValue(key, value, true)) {
            this.params[key] = value;
        }
    }

    validateParamValue<Key extends keyof T>(key: Key, value: T[Key], showLogs = false): boolean {
        if (typeof key !== 'string') {
            return false;
        }
        const option = this.getParams().product.threeDParameters.find(
            (option) => option.genericCode === key.toUpperCase(),
        );

        if (option) {
            if (option.type === 'INT') {
                if (typeof value !== 'number') {
                    if (showLogs) {
                        console.warn(
                            `${value} is invalid value for option ${option.name} in entry ${this.constructor.name}`,
                        );
                    }

                    return false;
                }
                if (!(value >= option.intValidation.min && value <= option.intValidation.max)) {
                    if (showLogs) {
                        console.warn(
                            `${value} is out of range for option ${option.name} in entry ${this.constructor.name}. Range is ${option.intValidation.min}-${option.intValidation.max}.`,
                        );
                    }

                    return false;
                }
                option.intValidation.min;
            } else if (option.type === 'SELECT') {
                const values = option.optionSelects.map((option) => option.value);

                if (!values.includes(value)) {
                    if (showLogs) {
                        console.warn(
                            `You are trying to set invalid value for option ${option.name} in entry ${
                                this.constructor.name
                            }.Options available: ${values.join(', ')}`,
                        );
                    }

                    return false;
                }
            }
        }

        return true;
    }
}
