import React, { FC } from 'react';
import {
    CardHorizontalWrapper,
    LeftWrapper,
    RightWrapper,
    LoadingWrapper,
    DetailsButtonWrapper,
} from './CardHorizontal.styled';
import { SubtitleS, SubtitleXS } from '@formify/frontend-components';
import { TextXS } from '@formify/frontend-components';
import { TextLinesLimitWrapper } from '@formify/frontend-components/dist/components/Typography/Helpers';
import { LinkXS } from '@formify/frontend-components';
import { Color } from '../../../../constants/Color.enum';
import { LoadingIcon } from '../../../icons/Loading/Loading';
import { Button } from '@formify/frontend-components';
import { ChevronRightSmallIcon } from '../../../icons/ChevronRight/ChevronRightSmall';
import { useImageComponent } from '../../../ImageWithShimmerEffect/ImageContextComponent';
import { Tr } from '../../../Translations/Tr';
import { useCurrency } from '../../../Currency/CurrencyProvider';

export interface CardHorizontalProps {
    title: string;
    subtitle: string;
    oldPrice?: string;
    price?: number;
    quantity?: number;
    onClick?: () => void;
    imgPath?: string;
    imgComponent?: React.FC<{}>;
    loading?: boolean;
    handleDetailsClick?: () => void;
    dataTestId?: string;
}

export const CardHorizontal: FC<CardHorizontalProps> = ({
    title,
    subtitle,
    onClick,
    imgPath,
    imgComponent,
    quantity,
    oldPrice,
    price,
    loading,
    handleDetailsClick,
    dataTestId,
}) => {
    const Image = useImageComponent();
    const ImgComponent = imgComponent;
    const { formatPrice } = useCurrency();

    return (
        <CardHorizontalWrapper data-test-id={dataTestId} onClick={onClick}>
            <LeftWrapper>
                {imgPath && !loading && !ImgComponent && (
                    <Image
                        src={imgPath}
                        alt={title}
                        height={166}
                        width={140}
                        style={{ objectFit: 'contain', width: '140px' }}
                    />
                )}
                {imgPath && !loading && ImgComponent && <ImgComponent />}
                {loading && (
                    <LoadingWrapper>
                        <LoadingIcon />
                    </LoadingWrapper>
                )}
            </LeftWrapper>
            <RightWrapper>
                {!loading && (
                    <>
                        <div className="flex flex-col">
                            <TextLinesLimitWrapper maxLines={1}>
                                <SubtitleXS className="text-left">{title}</SubtitleXS>
                            </TextLinesLimitWrapper>
                            <TextLinesLimitWrapper maxLines={3}>
                                <TextXS className="text-left">{subtitle}</TextXS>
                            </TextLinesLimitWrapper>
                        </div>
                        <div>
                            {oldPrice && (
                                <SubtitleXS color={Color.Error} className="line-through text-right">
                                    {formatPrice(oldPrice)}
                                </SubtitleXS>
                            )}
                            <div className="flex justify-between w-full items-center">
                                {typeof quantity === 'number' && (
                                    <LinkXS>
                                        <Tr labelKey="quantity_short" defaultValue="Qty." /> {quantity}
                                    </LinkXS>
                                )}
                                {handleDetailsClick && (
                                    <DetailsButtonWrapper>
                                        <Button variant="solid" onClick={handleDetailsClick}>
                                            <div className="flex left items-center">
                                                <SubtitleS className="mr-4 -mb-2">
                                                    <Tr labelKey="Details" defaultValue="Details" />
                                                </SubtitleS>
                                                <ChevronRightSmallIcon />
                                            </div>
                                        </Button>
                                    </DetailsButtonWrapper>
                                )}
                                <LinkXS hidden={price === undefined}>{price && formatPrice(price)}</LinkXS>
                            </div>
                        </div>
                    </>
                )}
            </RightWrapper>
        </CardHorizontalWrapper>
    );
};
