import { isOptionNames, OptionNames } from './OptionNames';
import { isObjectWithKey, isGenericArray } from '../../validateData';

type OptionImage = {
    path: string;
    type: string;
};

export type OptionSelectRaw = {
    id: number;
    value: string;
    name: string;
    description: string | null;
    image: OptionImage;
};

export type OptionSelect = {
    id: number;
    name: {
        [lang: string]: string;
    };
    value: string;
    image: OptionImage;
    description: {
        [lang: string]: string;
    };
};

export const DOOR_HANDLE_POSITION_CODES = ['LEFT', 'RIGHT'] as const;

export type ProductOptionGenericCode =
    | 'WIDTH'
    | 'HEIGHT'
    | 'MATERIAL'
    | 'DEPTH'
    | 'HINGES_NUMBER'
    | 'LEGS_NUMBER'
    | 'BOX_HEIGHT'
    | 'KITCHEN_LAYOUT_ID'
    | 'ALIGN'
    | null;

export type DoorHandlePositionCode = typeof DOOR_HANDLE_POSITION_CODES[number];

const availableProductCounterTopMaterialOption = ['BLACK_GRANITE', 'WHITE_ITALIAN_MARBLE'] as const;

export type ProductCounterTopMaterialOption = typeof availableProductCounterTopMaterialOption[number];

export const isCounterTopMaterialOption = (value: string): value is ProductCounterTopMaterialOption =>
    availableProductCounterTopMaterialOption.includes(value as ProductCounterTopMaterialOption);

export const availableProductWoodenMaterialOption = [
    'BLACK_WOOD',
    'NATURAL_WOOD',
    'WARM_WHITE',
    'WARM_GREY',
    'QUARTZ',
    'ELEGANT_BLACK',
    'NOCE_BARONA_NATURALE',
    'OLMO_ARALIE_BIANCO',
    'ZEMIRE_COFFEE',
    'NOCE_VANGUARD_LIGHT',
] as const;

export type ProductWoodenMaterialOption = typeof availableProductWoodenMaterialOption[number];

export const isProductMaterialOption = (value: string): value is ProductWoodenMaterialOption =>
    availableProductWoodenMaterialOption.includes(value as ProductWoodenMaterialOption);

interface CustomOptionBase {
    '@id': string;
    id: number;
    name: OptionNames;
    genericCode: ProductOptionGenericCode;
    type: string;
}

interface CustomOptionInt extends CustomOptionBase {
    type: 'INT';
    intValidation: { min: number; max: number };
}
interface CustomOptionSelectRaw extends CustomOptionBase {
    type: 'SELECT';
    optionSelects: OptionSelectRaw[];
}

interface CustomOptionSelect extends CustomOptionBase {
    type: 'SELECT';
    optionSelects: OptionSelect[];
}

export type ThreeDParameterRaw = CustomOptionInt | CustomOptionSelectRaw;

export type ThreeDParameter = CustomOptionInt | CustomOptionSelect;

function isOptionImage(data: unknown): data is OptionImage {
    return isObjectWithKey(data) && typeof data.type === 'string' && typeof data.path === 'string';
}

function isOptionSelect(data: unknown): data is OptionSelect {
    return (
        isObjectWithKey(data) &&
        typeof data.id === 'number' &&
        isObjectWithKey(data.name) &&
        isObjectWithKey(data.description) &&
        typeof data.value === 'string' &&
        isOptionImage(data.image)
    );
}

function isOptionSelectRaw(data: unknown): data is OptionSelectRaw {
    return (
        isObjectWithKey(data) &&
        typeof data.id === 'number' &&
        typeof data.name === 'string' &&
        (typeof data.description === 'string' || data.description === null) &&
        typeof data.value === 'string' &&
        isOptionImage(data.image)
    );
}

export function isCustomOptionInt(data: unknown): data is CustomOptionInt {
    return (
        isObjectWithKey(data) &&
        data.type === 'INT' &&
        typeof data['@id'] === 'string' &&
        isObjectWithKey(data.intValidation) &&
        typeof data.intValidation.min === 'number' &&
        typeof data.intValidation.max === 'number'
    );
}

export function isCustomOptionSelect(data: unknown): data is CustomOptionSelect {
    return (
        isObjectWithKey(data) &&
        data.type === 'SELECT' &&
        typeof data['@id'] === 'string' &&
        isGenericArray(data.optionSelects, isOptionSelect)
    );
}

export function isCustomOptionSelectRaw(data: unknown): data is CustomOptionSelectRaw {
    return (
        isObjectWithKey(data) &&
        data.type === 'SELECT' &&
        typeof data['@id'] === 'string' &&
        isGenericArray(data.optionSelects, isOptionSelectRaw)
    );
}

export function isCustomOptionRaw(data: unknown): data is ThreeDParameterRaw {
    const results =
        isObjectWithKey(data) &&
        typeof data.id === 'number' &&
        typeof data.type === 'string' &&
        isOptionNames(data.name) &&
        (isCustomOptionInt(data) || isCustomOptionSelectRaw(data));

    return results;
}

// function isCustomOption(data: unknown): data is ThreeDParameter {
//     const results =
//         isObjectWithKey(data) &&
//         typeof data.id === 'number' &&
//         typeof data.type === 'string' &&
//         isOptionNames(data.name) &&
//         (isCustomOptionInt(data) || isCustomOptionSelect(data));

//     return results;
// }
