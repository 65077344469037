import { useLayoutEffect, useState } from 'react';
import { useChannel } from '../Channel/ChannelProvider';
import { useLanguage } from '../Translations/LanguageProvider';
import Head from 'next/head';

export const OgMetaTags = () => {
    const [origin, setOrigin] = useState<string>('');
    const { name } = useChannel();
    const { currentLanguage } = useLanguage();

    useLayoutEffect(() => {
        if (typeof window !== 'undefined') {
            setOrigin(window.location.origin);
        }
    }, []);

    return (
        <>
            {origin !== '' ? (
                <Head>
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={name} />
                    <meta property="og:locale" content={currentLanguage} />

                    <meta property="og:image" content={`${origin}/ogImageSmall.jpg`} />
                    <meta property="og:image:width" content="600" />
                    <meta property="og:image:height" content="315" />

                    <meta property="og:image" content={`${origin}/ogImage.jpg`} />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />

                    <meta property="og:image" content={`${origin}/ogImageSquare.jpg`} />
                    <meta property="og:image:width" content="256" />
                    <meta property="og:image:height" content="256" />
                </Head>
            ) : null}
        </>
    );
};
