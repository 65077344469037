interface SplitNumberValueToArrayParams {
    min: number;
    max: number;
    value: number;
    length: number;
}
export const splitNumberValueToArray = ({ min, max, value, length }: SplitNumberValueToArrayParams): number[] => {
    const divisibleValue = value / length;

    if (length === 0 && value < min) {
        throw new Error('[splitNumberValueToArray] invalid params');
    }

    if (divisibleValue > max) {
        return splitNumberValueToArray({ min, max, value, length: length + 1 });
    } else if (divisibleValue < min) {
        return splitNumberValueToArray({ min, max, value, length: length - 1 });
    }

    if (divisibleValue / 10 === Math.round(divisibleValue / 10)) {
        return new Array<number>(length).fill(divisibleValue);
    }
    const baseValue = Math.floor(divisibleValue / 10) * 10;
    let rest = value - baseValue * length;
    const result = new Array<number>(length).fill(baseValue);
    let index = 0;

    while (rest >= 10) {
        result[index] += 10;
        index++;
        if (index === result.length) {
            index = 0;
        }
        rest -= 10;
    }

    return result;
};
