import { Product } from '../../../../types/Product';
import { DoorHandlePositionCode, ProductWoodenMaterialOption } from '../../../products/domain/ThreeDParameter';
import { WardrobeTreeVisitor } from '../visitor/domain';
import { NodeWithProduct } from './NodeWithProduct';

export interface DoorNodeParams {
    product: Product;
    width: number;
    height: number;
    material: ProductWoodenMaterialOption;
    position: DoorHandlePositionCode;
    hinges_number?: number;
}

export class DoorNode extends NodeWithProduct<DoorNodeParams> {
    visit<T extends WardrobeTreeVisitor<any>>(visitor: T): ReturnType<T['visitDoorNode']> {
        return visitor.visitDoorNode(this);
    }

    getParams(): DoorNodeParams {
        const params = super.getParams();
        const hinges_number = DoorNode.countNumberOfHinge(params.height);

        return {
            ...params,
            hinges_number,
        };
    }

    getNumberOfHinge() {
        const { height } = this.getParams();

        return DoorNode.countNumberOfHinge(height);
    }

    static countNumberOfHinge(height: number) {
        if (height > 260) {
            return 7;
        } else if (height > 240) {
            return 6;
        } else if (height > 220) {
            return 5;
        }

        return 4;
    }
}
