import { Product } from '../../../../types/Product';
import { WardrobeTreeVisitor } from '../visitor/domain';
import { NodeWithProduct } from './NodeWithProduct';

interface WoodDoorHandleNodeParams {
    product: Product;
}

export class WoodDoorHandleNode extends NodeWithProduct<WoodDoorHandleNodeParams> {
    visit<T extends WardrobeTreeVisitor<any>>(visitor: T): ReturnType<T['visitWoodDoorHandleNode']> {
        return visitor.visitWoodDoorHandleNode(this);
    }
}
