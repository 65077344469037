import { getPublicEnv } from '../env/env';
import { fetchUnAuthorizationApi } from '../fetch/fetch';
import { getString } from '../validateData';

export interface Channel {
    name: string;
    logo: string | null;
    favicon: string | null;
}

export class ChannelService {
    async fetchChannel(channelCode: string): Promise<Channel> {
        const apiUrl = await getPublicEnv('NEXT_PUBLIC_API_URL');
        const endpoint = `${apiUrl}/shop/channels/${channelCode}`;

        const response = await fetchUnAuthorizationApi(endpoint, {
            headers: {
                accept: 'application/json',
            },
        });

        if (isChannelResponse(response)) {
            return {
                name: getString(response.name, ''),
                logo: `${await getPublicEnv('NEXT_PUBLIC_API_DOMAIN')}/${await getPublicEnv('MEDIA_DOMAIN_URL')}${
                    response.image.path
                }`,
                favicon: response.thirdImage?.path
                    ? `${await getPublicEnv('NEXT_PUBLIC_API_DOMAIN')}/${await getPublicEnv('MEDIA_DOMAIN_URL')}${
                          response.thirdImage?.path
                      }`
                    : null,
            };
        }

        throw new Error(`Invalid data from '${endpoint}' URL`);
    }
}

interface ChannelResponse {
    name: string | null;
    image: {
        path: string;
    };
    thirdImage: {
        path: string;
    } | null;
}

function isChannelResponse(value: any): value is ChannelResponse {
    return (
        value && value.image && value.image.path && value.name && (value.thirdImage === null || value.thirdImage.path)
    );
}
