import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { GroupResultWithPriceWall } from '../../hooks/useProjectDetailsById';
import { chooseDepthVariant } from '../../services/nodes/wardrobe/wardrobe';
import { CardHorizontal } from '../Card/components/CardHorizontal/CardHorizontal';
import { formatMeasurements } from '../Card/utils';
import { SummaryDetailsGrid } from '../SidePanel/components/Summary/components/SummaryDetails/SummaryDetails.styled';
import { TextXS } from '@formify/frontend-components';
import { getConfigurations } from '../../store/configurations/selectors';
import { LinkM } from '@formify/frontend-components';
import { SubtitleS } from '@formify/frontend-components';
import { Accordion } from '../Accordion/Accordion';
import { Tr } from '../Translations/Tr';
import { useLanguage } from '../Translations/LanguageProvider';

interface ProjectDetailsProps {
    grouped: GroupResultWithPriceWall[];
}

export const ProjectDetails: FC<ProjectDetailsProps> = ({ grouped }) => {
    const layouts = useSelector(getConfigurations);
    const { t } = useLanguage();

    return (
        <div style={{ position: 'relative' }}>
            {grouped.map(({ columns, wall, depth, height, width }) => (
                <div key={wall}>
                    <Accordion
                        title={
                            <div className="py-20">
                                <LinkM style={{ color: 'inherit' }}>
                                    <Tr labelKey="wall" defaultValue="Wall" />
                                    {wall}
                                </LinkM>
                                <SubtitleS style={{ color: 'inherit' }}>
                                    <div>{formatMeasurements({ depth, height, width }, t)}</div>
                                </SubtitleS>
                            </div>
                        }
                        defaultOpen={true}
                    >
                        {columns.map(({ details, column }, index) => {
                            const configId = column.getConfigId();
                            const { depth, width, height } = column.getParams();

                            if (configId === null) {
                                return (
                                    <div key={index}>
                                        <CardHorizontal
                                            dataTestId={`custom_${index}`}
                                            subtitle={t('Custom layout')}
                                            title={formatMeasurements(
                                                {
                                                    width,
                                                    height,
                                                    depth,
                                                },
                                                t,
                                            )}
                                            imgPath="/images/broken.svg"
                                        />
                                        <TextXS>
                                            <SummaryDetailsGrid></SummaryDetailsGrid>
                                        </TextXS>
                                    </div>
                                );
                            }
                            const config = layouts[configId];
                            const layout = chooseDepthVariant(config, depth);
                            const image = layout?.image;

                            return (
                                <div key={index}>
                                    <CardHorizontal
                                        dataTestId={`layout_id_${config?.hash}`}
                                        subtitle={layout?.name || ''}
                                        title={formatMeasurements(
                                            {
                                                width,
                                                height,
                                                depth,
                                            },
                                            t,
                                        )}
                                        // quantity={quantity}
                                        // price={convertValueFromCentsToEuro(price)}
                                        imgPath={image}
                                    />
                                    <TextXS>
                                        <SummaryDetailsGrid>
                                            <div>
                                                <Tr labelKey="doors" defaultValue="Doors" />:
                                            </div>
                                            <div>
                                                {details.doors ? (
                                                    <Tr labelKey="With Doors" defaultValue="With Doors" />
                                                ) : (
                                                    <Tr labelKey="Without doors" defaultValue="Without doors" />
                                                )}
                                            </div>

                                            {details.doors && details.handle !== null && (
                                                <>
                                                    <div>
                                                        <Tr labelKey="handle" defaultValue="Handle" />:
                                                    </div>
                                                    <div>
                                                        <Tr
                                                            labelKey={`productName.${details.handle}`}
                                                            defaultValue={details.handle}
                                                        />
                                                    </div>
                                                </>
                                            )}

                                            {(details.shelf !== 0 || details.shelf_glass !== 0) && (
                                                <>
                                                    <div>
                                                        <Tr labelKey="shelves" defaultValue="Shelves" />:
                                                    </div>
                                                    <div>
                                                        {details.shelf !== 0 && (
                                                            <Tr
                                                                labelKey="projectDetails.number_of_shelves.wood"
                                                                defaultValue="${numberOfShelves} wooden shelves"
                                                                variables={{
                                                                    numberOfShelves: details.shelf,
                                                                }}
                                                            />
                                                        )}
                                                        {details.shelf_glass !== 0 && (
                                                            <Tr
                                                                labelKey="projectDetails.number_of_shelves.glass"
                                                                defaultValue="${numberOfShelves} glass shelves"
                                                                variables={{
                                                                    numberOfShelves: details.shelf_glass,
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </SummaryDetailsGrid>
                                    </TextXS>
                                </div>
                            );
                        })}
                    </Accordion>
                </div>
            ))}
        </div>
    );
};
