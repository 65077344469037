import { useSelector } from 'react-redux';
import { createAction } from '@reduxjs/toolkit';
import { AllColumnConfigurations } from './reducer';
import { RootState } from '../../store';
import { ColumnConfiguration } from '../../types/Configuration';
import { WardrobePriceResponse } from '../../types/Project';
import { ThunkApiConfig } from '../../store/index';
import { promiseDebounce } from '../utils';
import {
    createEmptyWrapper,
    createWardrobeStructureBasedOnState,
    getAllProducts,
} from '../../services/nodes/wardrobe/wardrobe';
import { PriceVisitor } from '../../services/nodes/wardrobe/visitor/PriceVisitor';
import { createAsyncThunkWithShowError } from '../utils/createAsyncThunkWithShowError';
import { getVisibleColumn } from '../wardrobe/selectors';
import { ProjectVisitor } from '../../services/nodes/wardrobe/visitor/ProjectVisitor';
import { ColumnNodeBuilder } from '../../services/nodes/wardrobe/builders/ColumnNodeBuilder';

type ConfigurationPriceParams = {
    configId: string;
    columnIndex: number;
    currencyCode: string;
};

export const fetchColumnConfigurationPrice = createAsyncThunkWithShowError<
    WardrobePriceResponse,
    ConfigurationPriceParams,
    ThunkApiConfig
>('configurations/fetchColumnConfigurationPrice', async ({ columnIndex, configId, currencyCode }, { getState }) => {
    const state = getState();
    const columns = getVisibleColumn(state);
    const products = getAllProducts(state);
    const selected = columns[columnIndex] || null;

    if (products === null) {
        return Promise.reject(new Error('products not exist'));
    }

    if (selected === null) {
        return Promise.reject(new Error('column not selected'));
    }

    if (!state.dependency.services) {
        return Promise.reject(new Error('Price service is not available'));
    }

    const columnBuilder = new ColumnNodeBuilder(products, state.configurations.allConfigurations);
    const wrapper = createEmptyWrapper(state);

    const columnWithItems = columnBuilder.buildColumn(
        {
            ...selected,
            data: {
                ...selected.data,
            },
            configurationId: configId,
        },
        state.wardrobe.led,
    );

    wrapper.addChild(columnWithItems);

    const priceVisitor = new ProjectVisitor();
    const formattedData = priceVisitor.visitWrapperNode(wrapper);

    return state.dependency.services.price.fetchPrice(formattedData, currencyCode);
});

export const fetchWardrobePrice = createAsyncThunkWithShowError<
    { unitPriceWithChildItems: number },
    { currencyCode: string },
    ThunkApiConfig
>(
    'configurations/fetchWardrobePrice',
    promiseDebounce(({ currencyCode }, { getState }) => {
        const state = getState();
        const wardrobe = createWardrobeStructureBasedOnState(state);

        if (wardrobe === null) {
            throw new Error('Wardrobe is not created');
        }

        if (!state.dependency.services) {
            return Promise.reject(new Error('Price service is not available'));
        }

        const priceVisitor = new PriceVisitor();
        const node = priceVisitor.visitWardrobeNode(wardrobe);

        return state.dependency.services.price.fetchPrice(node, currencyCode);
    }),
);

export const setAllConfigurations = createAction<AllColumnConfigurations>('configurations/setAllConfigurations');
