import { ColumnNode } from '../nodes/ColumnNode';
import { DoorNode } from '../nodes/DoorNode';
import { DoubleDrawerNode } from '../nodes/DoubleDrawerNode';
import { DrawerNode } from '../nodes/DrawerNode';
import { GlassShelveNode } from '../nodes/GlassShelveNode';
import { HangerFrontNode } from '../nodes/HangerFrontNode';
import { HangerNode } from '../nodes/HangerNode';
import { ShelveNode } from '../nodes/ShelveNode';
import { SpaceNode } from '../nodes/SpaceNode';
import { WardrobeNode } from '../nodes/WardrobeNode';
import { SomeNodeWithProduct, WardrobeTreeVisitor } from './domain';
import { ChromeDoorHandleNode } from '../nodes/ChromeDoorHandleNode';
import { GoldDoorHandleNode } from '../nodes/GoldDoorHandleNode';
import { WoodDoorHandleNode } from '../nodes/WoodDoorHandleNode';
import { ProductWoodenMaterialOption } from '../../../products/domain/ThreeDParameter';
import { LedNode } from '../nodes/LedNode';
import { WrapperNode } from '../nodes/WrapperNode';
import { WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { WardrobeWallNode } from '../nodes/WardrobeWallNode';
import { HangerFrontWithShelveNode } from '../nodes/HangerFrontWithShelveNode';
import { DEFAULT_LOCALE_CODE } from '../../../../store/project/selectors';

interface ColumnDetails {
    shelf: number;
    shelf_glass: number;
    layout: null | string;
    depth: number;
    height: number;
    width: number;
    material: ProductWoodenMaterialOption;
    handle: string | null;
    doors: boolean;
    wall: WardrobeWall;
}

interface ColumnResult {
    column: ColumnNode;
    details: ColumnDetails;
}

export interface GroupResult {
    column: ColumnNode;
    details: ColumnDetails;
    quantity: number;
}

interface GroupResultWall {
    columns: GroupResult[];
    wall: WardrobeWall;
}

const indexToWardrobeWall: { [key: number]: WardrobeWall } = {
    0: 'A',
    1: 'B',
    2: 'C',
};

export class GroupedVisitor
    implements
        WardrobeTreeVisitor<
            null,
            GroupResultWall[],
            null,
            null,
            null,
            null,
            null,
            ColumnResult,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            GroupResult[],
            null
        >
{
    visitWardrobeNode(node: WardrobeNode): GroupResultWall[] {
        const columnNodes = node.findChildrenByInstance([WardrobeWallNode]);

        if (columnNodes.length <= 1) {
            return [
                {
                    columns: this.joinColumnToGroup(node),
                    wall: 'A',
                },
            ];
        }

        return columnNodes
            .map((node) => node.visit(this))
            .map((columns, index) => ({
                wall: indexToWardrobeWall[index] || 'A',
                columns,
            }));
    }

    visitColumnNode(node: ColumnNode): ColumnResult {
        const { layout, depth, height, width, material, wall } = node.getParams();
        const handleNodes = node.findChildrenByInstance([GoldDoorHandleNode, ChromeDoorHandleNode, GoldDoorHandleNode]);

        const details: ColumnDetails = {
            shelf: node.findChildrenByInstance([ShelveNode]).length,
            shelf_glass: node.findChildrenByInstance([GlassShelveNode]).length,
            layout,
            depth,
            height,
            width,
            material,
            handle: handleNodes[0] ? handleNodes[0].getParams().product.name[DEFAULT_LOCALE_CODE] ?? null : null,
            doors: !!node.findChildrenByInstance([DoorNode]).length,
            wall,
        };

        return {
            column: node,
            details,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitShelveNode(node: ShelveNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitHangerFrontNode(node: HangerFrontNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitHangerNode(node: HangerNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitGlassShelveNode(node: GlassShelveNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitDrawerNode(node: DrawerNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitSpaceNode(node: SpaceNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitDoubleDrawerNode(node: DoubleDrawerNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitDoorNode(node: DoorNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitChromeDoorHandleNode(node: ChromeDoorHandleNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitGoldDoorHandleNode(node: GoldDoorHandleNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitWoodDoorHandleNode(node: WoodDoorHandleNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitLedNode(node: LedNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitWrapperNode(node: WrapperNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitHangerFrontWithShelve(node: HangerFrontWithShelveNode): null {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    visitWardrobeWallNode(node: WardrobeWallNode): GroupResult[] {
        return this.joinColumnToGroup(node);
    }

    private joinColumnToGroup(node: SomeNodeWithProduct): GroupResult[] {
        const columnNodes = node.findChildrenByInstance([ColumnNode]);

        const grouped = columnNodes.map(this.visitColumnNode).reduce<GroupResult[]>((acc, value) => {
            const group = acc.find((accData) => JSON.stringify(accData.details) === JSON.stringify(value.details));

            if (group) {
                group.quantity += 1;
            } else {
                acc.push({
                    column: value.column,
                    details: value.details,
                    quantity: 1,
                });
            }

            return acc;
        }, []);

        return grouped;
    }
}
