import { createAction } from '@reduxjs/toolkit';
import { ColumnElement, WardrobeWall } from '@formify/frontend-wardrobe-renderer/dist/src/context/domain';
import { DoorHandlePositionCode, ProductWoodenMaterialOption } from '../../services/products/domain/ThreeDParameter';
import { DoorHandleCode, ShelveCode, WardrobeCode } from '../../services/products/domain/ProductCodes';
import { Product } from '../../types/Product';
import { OptionDimensions } from '../products/utils';
import { WardrobeColumn } from './reducer';

interface AssignConfigurationToColumnParam {
    columnIndex: number;
    configurationId: string;
}

export const assignConfigurationToColumn = createAction<AssignConfigurationToColumnParam>(
    'wardrobe/assignConfigurationToColumn',
);

interface AssignDoorParam {
    door: Product | null;
    availableHandles: DoorHandleCode[];
}

interface AssignShelve {
    shelveType: ShelveCode;
}

export const assignDoor = createAction<AssignDoorParam>('wardrobe/assignDoor');

export const assignShelves = createAction<AssignShelve>('wardrobe/assignShelves');

export const assignDoorHandleType = createAction<DoorHandleCode | null>('wardrobe/assignDoorHandleType');

export const assignDoorHandlePosition = createAction<DoorHandlePositionCode>('wardrobe/assignDoorHandlePosition');

interface ActionDimensionsParam {
    type: keyof OptionDimensions;
    value: number;
}

interface WardrobeColumnsWidth {
    columns: number[];
}

export const setWardrobeDimensions = createAction<ActionDimensionsParam>('wardrobe/setWardrobeDimensions');

interface ActionWallDimensionsParam {
    type: keyof OptionDimensions;
    value: number;
    wall: WardrobeWall;
}

export const setWallDimensions = createAction<ActionWallDimensionsParam>('wardrobe/setWallDimensions');

export const setSelectedColumnDimensions = createAction<ActionDimensionsParam>('wardrobe/setSelectedColumnDimensions');

export const setSelectedColumnIndex = createAction<number | null>('wardrobe/setSelectedColumnIndex');

export const setColumns = createAction<WardrobeColumn[]>('wardrobe/setColumns');

interface SetColumnsWidthParam {
    columnsWidth: number[];
    wall: WardrobeWall;
}
export const setColumnsWidth = createAction<SetColumnsWidthParam>('wardrobe/setColumnsWidth');

export const setVisibility = createAction<number>('wardrobe/setVisibility');

export const setWoodColor = createAction<ProductWoodenMaterialOption>('wardrobe/setWoodColor');

export const setLed = createAction<boolean>('wardrobe/setLed');

export const setWardrobeType = createAction<WardrobeCode>('wardrobe/setWardrobeType');

export const setLazyWidth = createAction<number | null>('wardrobe/setLazyWidth');

export const setElementToSelectedColumn = createAction<ColumnElement[]>('wardrobe/setElementToSelectedColumn');
